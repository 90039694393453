import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ChoosedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#0094FF" fillOpacity="0.2" />
        <path d="M8.00024 12.8888L11.1114 15.9999L16.0002 9.33328" stroke="#0094FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default ChoosedIcon;
