export interface IObject {
  [key: string]: any;
};

export interface IIconProps {
  type?: string;
  className?: string;
  svgClassName?: string;
  size?: number;
  opacity?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export interface IDeliveryAddress {
  name: string;
  phone: string;
  delivery_date?: string;
  country?: string;
  province?: string;
  city?: string;
  state?: string;
  district?: string;
  street?: string;
  address?: string;
  lat?: string;
  lng?: string;
};

export enum IDiningMethods {
  TAKEAWAY = 0,
  DELIVERY = 1,
  DINE_IN = 2,
};

export interface IPickUpOption {
  name: string;
  phone: string;
  delivery_date: string;
}
