import { getIntl } from '@/App';
import { prefix } from '.';
import { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { queryTableDetails } from '@/interface/table';
import ScanCodeFailedIcon from '../svg/ScanCodeFailedIcon';

interface IDineInProcessProps {
  visible: boolean;
  businessID: string;
  onConfirm: (option?: any) => void;
}

let tipsTimeout: any;

const DineInProcess: React.FC<IDineInProcessProps> = (props) => {
  const { visible, businessID, onConfirm } = props;

  const html5QrCodeRef = useRef<any>(null);
  const isScanning = useRef(false);
  const [msg, setMsg] = useState<string>('');

  useEffect(() => {
    return () => {
      stopScan();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!visible) {
      stopScan();
    } else {
      startScan();
    };

    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if (msg) {
      clearTimeout(tipsTimeout);
      tipsTimeout = setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  }, [msg]);

  const config = { fps: 10, qrbox: 250, aspectRatio: 1 };

  const qrCodeSuccessCallback = async (text: any) => {
    const params = `${text}`.split('?')[1] || '';

    let table = undefined;
    if (params) {
      const paramsArr = params.split('&');

      for (let i = 0; i < paramsArr.length; i++) {
        const item = paramsArr[i];
        const [label = '', value = ''] = item.split('=');
        if (label === 'table' && !isNaN(Number(value))) {
          table = await queryTableDetails(businessID, value);
          break;
        }
      }
    };

    if (table) {
      onConfirm(table)
    } else {
      setMsg(getIntl().common.invalidOrTips);
    }

    console.log('扫描结果:', `${text}`);
  };

  let lastErrorTime = 0;
  let qrCodeErrorCallback = (errorMessage: any) => {
    const now = new Date().getTime();
    if (now - lastErrorTime > 1000) {
      lastErrorTime = now;
      console.log(`扫码错误: ${errorMessage}`);
    }
  };

  const startScan = async () => {
    if (isScanning.current) {
      return;
    };

    const html5QrCode = new Html5Qrcode('reader');
    html5QrCodeRef.current = html5QrCode;

    html5QrCodeRef.current.start(
      { facingMode: 'environment' },
      config,
      qrCodeSuccessCallback,
      qrCodeErrorCallback
    ).catch((err: any) => {
      console.log('摄像头启动失败: ', err);
    });

    isScanning.current = true; 
  };

  const stopScan = async () => {
    if (html5QrCodeRef.current) {
      try {
        await html5QrCodeRef.current.stop();
        isScanning.current = false;
      } catch (error) {
        console.error('停止扫码失败:', error);
      }
    };
    setMsg('');
  };
  

  return (
    <div className={`${prefix}-dineIn`}>
      <div className={`${prefix}-dineIn-title`}>{getIntl().common.scanTips}</div>
      <div className={`${prefix}-dineIn-content`}>
        <div id='reader'></div>
        {
          msg && 
          <div className={`${prefix}-dineIn-content-msg`}>
            <ScanCodeFailedIcon />
            <div className='value'>{msg}</div>
          </div>
        }
      </div>
    </div>
  )
};

export default DineInProcess;