import api from "@/libs/api";
import Config from "@/libs/config";
import { IObject } from "@/types/common-types";

export const queryBusiness = async (businessID: string) => {
  let business: IObject = {};
  try {
    const result: any = await api.get(
      `${Config.urls.mainApi}/b2c/app/payment_link/store/${businessID}?search_templates=wonder`,
      {
        method: 'GET',
        headers: {
          'x-p-business-id': businessID,
        },
      }
    );
    
    if (result) {
      business = result
    }
  } catch (error) {}
  return business;
};




