import { Popup } from 'react-vant'
import { prefix } from '.';
import DeliveryProcess from './DeliveryProcess';
import { useEffect, useMemo, useState } from 'react';
import { getIntl } from '@/App';
import { IDeliveryAddress, IDiningMethods } from '@/types/common-types';
import TakeawayProcess from './TakeawayProcess';
import DineInIcon from '../svg/DineInIcon';
import TakeawayIcon from '../svg/TakeawayIcon';
import DeliveryIcon from '../svg/DeliveryIcon';
import DineInProcess from './DineInProcess';

interface IDiningMethodsProps {
  visible: boolean;
  type: IDiningMethods | undefined;
  types: IDiningMethods[];
  addressList: IDeliveryAddress[];
  businessID: string;
  setType: (type: IDiningMethods) => void;
  setAddressList: (record: IDeliveryAddress[]) => void;
  onClose: () => void;
  onConfirm: (option?: any) => void;
  handleChangeAddress: (index: number) => void;
}

const DiningMethods: React.FC<IDiningMethodsProps> = (props) => {
  const {
    visible,
    type,
    types,
    addressList,
    businessID,
    setType,
    onConfirm,
    onClose,
    setAddressList,
    handleChangeAddress,
  } = props;

  const [title, setTitle] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [showPickTime, setShowPickTime] = useState<boolean>(false);
  const [selectKey, setSelectIndex] = useState<string>('1');

  useEffect(() => {
    if (type) {
      resetTitle();
    }
    // eslint-disable-next-line
  }, [type]);

  const handleClose = () => {
    onClose();
    resetState();
  };

  const handleConfirm = (option?: any) => {
    onConfirm(option);
    resetState();
  };

  const resetTitle = () => {
    let title = '';
    if (type === IDiningMethods.DINE_IN) {
      title = getIntl().common.dineInTitle;
    } else {
      if (type === IDiningMethods.TAKEAWAY) {
        title = getIntl().common.takeawayTitle;
      } else {
        title = getIntl().common.deliveryTitle;
      }
    };
    setTitle(title);
  }

  const resetState = () => {
    setTimeout(() => {
      setShowForm(false);
      setShowSearch(false);
      setIsUpdate(false);
      setShowTabs(true);
      setShowPickTime(false);
      setSelectIndex('1');
      resetTitle();
    }, 500);
  };

  const chooseItems = useMemo(() => {

    let nodes = types.map(item => {
      let node = null;
      switch (item) {
        case IDiningMethods.DINE_IN:
          node = (
            <>
              <DineInIcon />
              <div className={`${prefix}-choose-item-title`}>{getIntl().common.dineIn}</div>
            </>
          )
          break;
        case IDiningMethods.TAKEAWAY:
          node = (
            <>
              <TakeawayIcon />
              <div className={`${prefix}-choose-item-title`}>{getIntl().common.takeaway}</div>
            </>
          )
          break;
        case IDiningMethods.DELIVERY:
          node = (
            <>
              <DeliveryIcon />
              <div className={`${prefix}-choose-item-title`}>{getIntl().common.delivery}</div>
            </>
          )
          break;
  
        default:
          break;
      }
  
      return (
        <div key={item} onClick={() => setType(item)} className={`${prefix}-choose-item ${type === item ? 'active' : ''}`}>
          {node}
        </div>
      );
    })
    return nodes;
    // eslint-disable-next-line
  }, [types, type]);

  return (
    <Popup
      visible={visible}
      closeable
      round
      position='bottom'
      style={{ height: '90%' }}
      onClose={handleClose}
    >
      <div className={`${prefix}`}>
        <div className={`${prefix}-title`}>{title}</div>
        {
          types.length > 1 && showTabs &&
          <div className={`${prefix}-choose`}>
            {chooseItems}
          </div>
        }
        <div className={`${prefix}-content`}>
          {
            type === IDiningMethods.DELIVERY &&
            <DeliveryProcess
              addressList={addressList}
              showForm={showForm}
              showSearch={showSearch}
              isUpdate={isUpdate}
              setShowTabs={setShowTabs}
              setTitle={setTitle}
              setIsUpdate={setIsUpdate}
              setShowForm={setShowForm}
              setShowSearch={setShowSearch}
              onConfirm={handleConfirm}
              setAddressList={setAddressList}
              handleChangeAddress={handleChangeAddress}
            />
          }
          {
            type === IDiningMethods.TAKEAWAY &&
            <TakeawayProcess
              selectKey={selectKey}
              showPickTime={showPickTime}
              setShowPickTime={setShowPickTime}
              setSelectIndex={setSelectIndex}
              setShowTabs={setShowTabs}
              setTitle={setTitle}
              onConfirm={handleConfirm}
            />
          }
          {
            type === IDiningMethods.DINE_IN &&
            <DineInProcess
              visible={visible}
              businessID={businessID}
              onConfirm={handleConfirm}
            />
          }
        </div>
      </div>
    </Popup>
  )
};

export default DiningMethods;