import { IBusiness } from "@/types/business-types";
import { prefix } from ".";
import { handleNameDisplay } from "@/utils";
import { Sticky } from 'react-vant';

interface IBusinessProps {
  business: IBusiness;
}

const Business: React.FC<IBusinessProps> = (props) => {
  const { businessName } = props.business

  return (
    <Sticky>
      <div className={`${prefix}`}>
        <div className={`${prefix}-title`}>{handleNameDisplay(businessName)}</div>
        <div className={`${prefix}-name`}>{businessName}</div>
      </div>
    </Sticky>
  )
};

export default Business;