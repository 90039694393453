import api from "@/libs/api";
import Config from "@/libs/config";
import { IMenuData } from "@/types/menu-types";

export const queryMenus = async (businessID: string): Promise<IMenuData[] | undefined> => {
  let list: IMenuData[] = [];
  try {
    const resp = await api.get(`${Config.urls.smartpos}/public/api/v1/device/menu_listings`, {
      headers: {
        'x-p-business-id': businessID,
      }
    });

    if (resp && resp.data && Array.isArray(resp.data.favorite_tabs)) {
      list = resp.data.favorite_tabs;
    }
  } catch (error) {
    console.error(error);
    return list;
  }

  return list;
}