let env: string = process.env.REACT_APP_SERVER_ENV || 'development';
const isDev = env === 'development';

if (isDev) {
  env = 'staging';
  // env = 'alpha';
  // env = 'production';
}

const platformId = 124;

let clientID = 'debb2260-6ecb-4f19-9c94-af4c6b4f90bb';
let dashboardClientID = '3b95c5102ac5973893fdb3b64bce4206';
let xAppSlug = '3Kswi8';
let xAppKey = '02eb3362-1ccb-4063-8f5e-825fde761efb';
let appAuthorization =
  'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiMDJlYjMzNjItMWNjYi00MDYzLThmNWUtODI1ZmRlNzYxZWZiIiwiYXBwX2lkIjoiODBhOTg0ZTItNGVjNC00ZDA2LWFiYTktZTQzMDEwOTU2ZTEzIiwiaWF0IjoxNjgxMzkyMzkyLCJleHAiOjE5OTY3NTIzOTJ9.2UF7FOI-d344wJsZt5zVg7dC2r1DzqdmSV_bhSpdt-I';
let publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj/2Ze7W7+LPe0A44Mded
I4G9dQ6P3dT5bv9awZnvj4UdDEjHgvpIHR+aEbWNNa1DrLVPCh6avGyI9ZoWhcsq
Uk1fqCQrd85cXJ3JpgKeaMCX7BNdW0RTirWGpO+v2WjTz1DFKaHGBjuESLZ+GRpi
bh93k/ErvMLal5UWfbv0H17m0dyvEkYPLmO37nfqQDfJx1TwAfYvYoANlSRI5+VG
0afhYyG59GdHyTgbVioD+zYWsCVwmqocZ2GodUZN3KX3px8Aj31dsutgc/PqoeLd
ejN+1qbaiYUoBagpS6gGCup5PM6G8KRjD7GyH17s+Qh1eozB2NPTPDOy6JrZU5u9
OQIDAQAB
-----END PUBLIC KEY-----`;
let cdnUrl = '';

let baseUrl = 'http://127.0.0.1:8000/api';
let gatewayUrl = 'https://gateway-stg.wonder.app';
let mainBindo = 'https://main-stg.bindo.co';
let baseDomain = 'https://hq-stg.wonder.app';
let payDomain = 'https://pay-stg.wonder.app';
let todayUrl = 'https://gateway-stg.wonder.today';



if (env === 'production') {
  clientID = 'f5ccfeb1-6fe7-40c8-952b-48d2aaff5d13';
  dashboardClientID = '0fcefe4bfc5c399c313309c40324fde8';
  xAppSlug = '3rTiiv';
  xAppKey = '9a54ed52-7a2c-4d08-aabc-4e1c548fff02';
  appAuthorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiOWE1NGVkNTItN2EyYy00ZDA4LWFhYmMtNGUxYzU0OGZmZjAyIiwiYXBwX2lkIjoiMDJjYTAxZmYtNzZkOC00NTQyLWE1Y2YtMmU1YzY1ZTQ0MmI4IiwiaWF0IjoxNjgyMDEwMTg5LCJleHAiOjE5OTczNzAxODl9.tr9s1n6YmqvubeXmsZvRTBN-B4UcaVOrT4gjjFpO6QM';
  publicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArH6oGwhwBJvJMbXUZuZZ
  hkhfLAf9xOkJaLGW9LgykQlCJnLtVQsgfXWLlc5y5w+LcjXnFMeTVycLj11+oBiR
  vJ5o7cLr8kKMVXwQJg/IVbg+LU30baOycIC5S6PoK1L0MFH85DFg1GSMDyU/07gj
  nT3LZip/GnNWLJYQ4UNAVCH/XXs5W8WDU+8WT9q1d6YwDBJWMkqvLSdPMZeL9w3U
  dqgpqQMztjW/D/JBChMxOi/LdGM3Xu/wLj9GqBOBYBiL+7nD2GflqznMJaiuykyc
  TR1f6i8AARfw0tJaLZBUpljbrPFNGtBDNT/jP/GvZNMk1N3C74uWtZ71XztxcITN
  mwIDAQAB
  -----END PUBLIC KEY-----`;
  baseDomain = 'https://hq.wonder.app';
  payDomain = 'https://pay.wonder.app';
  gatewayUrl = 'https://gateway.wonder.app';
  mainBindo = 'https://main.bindo.co';
  todayUrl = 'https://gateway.wonder.today';
} else if (env === 'alpha') {
  clientID = 'c6af384c-55de-43e3-bdd9-dbb15ad5db8b';
  dashboardClientID = '9e206e9906b9a824e2b3ea490767eae7';
  xAppSlug = 'JgG9C';
  xAppKey = '6bad4911-baa7-4588-997c-09d23d1072df';
  appAuthorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfa2V5IjoiNmJhZDQ5MTEtYmFhNy00NTg4LTk5N2MtMDlkMjNkMTA3MmRmIiwiYXBwX2lkIjoiNDdlNDg4NjQtNTg4Zi00OTk4LTg5MjUtNmE3MWY4ZDUyMTJjIiwiaWF0IjoxNjgxNDY3MTg2LCJleHAiOjE5OTY4MjcxODZ9.QVBAtihGkf0vhfaFgENg3RGqyp0stm-PmRLQATJzrI4';
  publicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtGwZH92LcFZ0CHzPz8A8
  TDBwPHJri8yjitebk7dps7m838mwB/HKuJQbZIwwi/06FpTyldmSbc8+9iO0nHv+
  /flowljnCeL/OxZjWP3Yvx/2838T05CV86vt0wMCxsGNn+QOBbVYYnAZxnaGRA3o
  R8OUA0brqOFE0rcRKV8D2JvXbq6tqTmFICNe//fAC7EEr2PWBThlTF105v1rPhNP
  hFMVBi2aWL8903NdaIpQhgiRRynqOEaGXJar2az6oUb8SnBsSjaV/jHoG33vt9IW
  PVC1KaN6WIXAOc5BiA9/wWo3QD5vlB2UF2WVTxYAES3VHqLgmVoPuHRuLS/kVV25
  FwIDAQAB
  -----END PUBLIC KEY-----`;
  baseDomain = 'https://hq-alpha.wonder.app';
  payDomain = 'https://pay-alpha.wonder.app';
  gatewayUrl = 'https://gateway-alpha.wonder.app';
  mainBindo = 'https://main-alpha.bindo.co';
  todayUrl = 'https://gateway-alpha.wonder.today';
}

const Config = {
  isDev: true,
  env: env,
  isStaging: env === 'development' || env === 'staging',
  isAlpha: env === 'alpha',
  isProd: env === 'production',
  baseUrl,
  clientID,
  dashboardClientID,
  xAppSlug,
  xAppKey,
  platformId,
  appAuthorization,
  publicKey,
  cdnUrl,
  urls: {
    baseDomain,
    gatewayUrl,
    mainBindo,
    mainApi: `${mainBindo}/api`,
    payDomain,
    todayUrl,
    smartpos: `${todayUrl}/svc/pos`
  },
}

export default Config;
