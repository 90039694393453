import api from "@/libs/api";
import Config from "@/libs/config";
import { IObject } from "@/types/common-types";
import { IOrderParams } from "@/types/order-types";

export const overbooking = async (businessID: string, orderData: IOrderParams) => {
  let result: IObject | undefined = undefined;
  try {
    const resp: any = await api.post(`${Config.urls.todayUrl}/svc/oms/public/api/v1/device/orders?with_payment_link=true`,
    orderData,
    {
      headers: {
        'x-p-business-id': businessID,
        'X-Platform-From': 4,
      }
    });

    if (resp.code === 200 && resp.data &&  resp.data.payment_link) {
      result = resp.data;
    };

  } catch (error) {
    console.error(error);
    return result;
  }

  return result;
}