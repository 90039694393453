import api from "@/libs/api";
import Config from "@/libs/config";
import { IObject } from "@/types/common-types";

interface IGetAddresOptionsByGoogleParams {
  query: string;
  action: 'autocomplete' | 'details';
}

export const queryGoogleService = async (
  params: IGetAddresOptionsByGoogleParams
) => {
  const { query, action } = params;
  let data: IObject = {};
  try {
    const resp: IObject = await api.get(
      `${Config.urls.gatewayUrl}/api/google-map/place/${action}/json?${query}&components=country:HK`,
    );

    if (resp && resp.code === 200 && resp.data) {
      data = resp.data;
    }
  } catch (error) {
    console.error(error);
  }

  return data;
};
