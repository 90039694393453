import { Outlet, useParams } from "react-router-dom";
import { prefix } from ".";
import { useEffect } from "react";
import { IDispatch } from '@/actions';
import { useDispatch } from "react-redux";
import ActionType from "@/actions/action-type";

const Layoutcomponent = () => {
  const { business_id = '' } = useParams();
  const dispatch: IDispatch = useDispatch();
  
  useEffect(() => {
    if(business_id) {
      dispatch({
        type: ActionType.LOADING_MAIN_DATA,
        businessId: business_id
      });
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className={`${prefix}`}>
      <Outlet />
    </div>
  )
};

export default Layoutcomponent;