enum ActionType {

  LOADING_MAIN_DATA = 'LOADING_MAIN_DATA',
  SET_BUSINESS = 'SET_BUSINESS',
  SET_MENUS = 'SET_MENUS',

  ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART',
  SET_SHOPPING_CART_DATA = 'SET_SHOPPING_CART_DATA',
  CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART',
}

export default ActionType;
