import { Button, Popup } from "react-vant";
import { prefix } from ".";
import { getIntl } from "@/App";
import { IFavorite } from "@/types/menu-types";
import { IShoppingCartInfo } from "@/types/order-types";
import MenuList from "../MenuList";
import { plus } from "@/utils";
import { IDiningMethods } from "@/types/common-types";
import { initialDelivery } from "@/constants";

interface IShoppingCaetPopupProps {
  data: IFavorite[];
  visible: boolean;
  type: IDiningMethods | undefined;
  shoppingCartData: IShoppingCartInfo | undefined;
  disabled: boolean;
  onClose: () => void;
  handleCheckout: () => void;
  handleMinusShoppingCart: (favorite: IFavorite) => void;
  handleAddShoppingCart: (favorite: IFavorite) => void;
};

const ShoppingCaetPopup: React.FC<IShoppingCaetPopupProps> = (props) => {
  const {
    data,
    type,
    visible,
    disabled,
    shoppingCartData,
    onClose,
    handleCheckout,
    handleMinusShoppingCart,
    handleAddShoppingCart,
  } = props;
  const { favoriteIdLineItemRef = {} } = shoppingCartData || {};
  const currency = 'HK$';

  let price = 0;
  let totalQuanity = 0;
  let deliveryFee = 0;

  if (type === IDiningMethods.DELIVERY) {
    deliveryFee = initialDelivery;
  }
  Object.values(favoriteIdLineItemRef).forEach(item => {
    const { quantity = 0, total = '0' } = item;
    totalQuanity = plus(totalQuanity, quantity);
    if (!isNaN(Number(total))) {
      price = plus(price, Number(total), 2);
    };
  });

  const list = [
    {
      value: price,
      label: getIntl().common.subtotal
    },
    {
      value: deliveryFee,
      label: getIntl().common.deliveryFee
    },
    {
      value: plus(price, deliveryFee),
      label: getIntl().common.total
    },
  ];

  return (
    <Popup
      visible={visible}
      closeable
      round
      position='bottom'
      style={{ height: '90%' }}
      onClose={onClose}
      className={`${prefix}-popup`}
    >
      <div className={`${prefix}`}>
        <div className={`${prefix}-title`}>{getIntl().common.cart}</div>
        <div className={`${prefix}-subtitle`}>{totalQuanity} {getIntl().common.items}</div>
        {
          totalQuanity > 0 &&
          <div className={`${prefix}-content`}>
            <MenuList
              data={data}
              isFirst={false}
              className={`${prefix}-menus`}
              shoppingCartData={shoppingCartData}
              handleMinusShoppingCart={handleMinusShoppingCart}
              handleAddShoppingCart={handleAddShoppingCart}
            />
            <div className={`${prefix}-statistics`}>
              {
                list.map((item, index) => (
                  <div key={item.label} className={`${prefix}-statistics-item ${index === 2 ? 'total' : ''}`}>
                    <div className={`${prefix}-statistics-item-label`}>{item.label}</div>
                    <div className={`${prefix}-statistics-item-value`}>{currency} {item.value}</div>
                  </div>
                ))
              }
            </div>
          </div>
        }
        <Button onClick={handleCheckout} disabled={disabled || totalQuanity < 1} type='primary'>{getIntl().common.confirmAndCheckout}({currency}{price})</Button>
      </div>
    </Popup>
  )
};

export default ShoppingCaetPopup