import Layoutcomponent from "@/components/Layout";
import { createBrowserRouter } from "react-router-dom";
import paths from "./paths";
import { ReactNode, Suspense } from "react";
import MenuListPage from "./MenuListPage";

const lazyComponent = (element: ReactNode): ReactNode => {
  return <Suspense fallback={<></>}> {element} </Suspense>
}

const routes = createBrowserRouter([
  {
    element: <Layoutcomponent />,
    children: [
      {
        path: paths.MENU_LIST,
        element: lazyComponent(<MenuListPage />)
      }
    ]
  }
]);

export default routes;