import React from 'react';
import type { IIconProps } from '@/types/common-types';

const EditAddressIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.00807 2.33212C9.10737 2.23751 9.23981 2.18561 9.37696 2.18757C9.51411 2.18953 9.64501 2.24519 9.74157 2.3426L12.597 5.22267C12.6474 5.27346 12.6869 5.33405 12.713 5.40069C12.7391 5.46733 12.7513 5.53861 12.7488 5.61014C12.7463 5.68167 12.7292 5.75192 12.6985 5.81657C12.6678 5.88123 12.6242 5.9389 12.5703 5.98604L5.68999 12.0123C5.59162 12.0986 5.46453 12.145 5.33371 12.1423L2.7832 12.0878C2.64616 12.0849 2.5157 12.0284 2.41981 11.9305C2.32392 11.8325 2.27023 11.7009 2.27026 11.5639V8.97352C2.27025 8.90259 2.28464 8.8324 2.31256 8.76719C2.34048 8.70199 2.38134 8.64313 2.43268 8.59419L9.00807 2.33212ZM9.70333 10.7559L13.6302 10.8324C13.699 10.8338 13.7669 10.8487 13.8299 10.8762C13.893 10.9038 13.95 10.9435 13.9977 10.9931C14.0454 11.0427 14.0829 11.1012 14.108 11.1653C14.1331 11.2294 14.1453 11.2978 14.1439 11.3666C14.1426 11.4354 14.1277 11.5033 14.1001 11.5663C14.0726 11.6294 14.0329 11.6864 13.9833 11.7341C13.9337 11.7818 13.8751 11.8192 13.8111 11.8443C13.747 11.8694 13.6786 11.8816 13.6098 11.8803L9.68289 11.8038C9.61409 11.8024 9.54623 11.7875 9.48319 11.7599C9.42015 11.7323 9.36316 11.6926 9.31548 11.6429C9.26781 11.5933 9.23037 11.5348 9.20531 11.4707C9.18025 11.4066 9.16806 11.3382 9.16944 11.2694C9.17081 11.2006 9.18573 11.1327 9.21333 11.0697C9.24093 11.0067 9.28068 10.9497 9.3303 10.902C9.37993 10.8543 9.43846 10.8169 9.50255 10.7918C9.56665 10.7668 9.63505 10.7546 9.70385 10.7559H9.70333ZM9.3591 3.44496L3.31813 9.19776V11.0509L5.15191 11.0902L11.4606 5.5648L9.3591 3.44496ZM13.6045 13.3756C13.7435 13.3746 13.8772 13.4288 13.9762 13.5263C14.0752 13.6238 14.1314 13.7567 14.1324 13.8956C14.1335 14.0346 14.0793 14.1683 13.9817 14.2673C13.8842 14.3662 13.7514 14.4224 13.6124 14.4235L3.32232 14.5C3.18337 14.501 3.04969 14.4468 2.9507 14.3493C2.8517 14.2518 2.7955 14.1189 2.79446 13.98C2.79342 13.841 2.84762 13.7073 2.94514 13.6083C3.04266 13.5094 3.17551 13.4532 3.31447 13.4521L13.6045 13.3756Z" fill="black" />
      </svg>
    </div>
  );
};

export default EditAddressIcon;
