import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import createStore from '@/store';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import routes from '@/pages/routes';
import locales from './locales';
import { getLangPacks } from './utils/language-utils';

let currentLocals = locales;
export const getIntl = () => currentLocals;
export const setIntl = (lang: string) => getLangPacks(lang);

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={createStore()}>
        <RouterProvider
          router={routes}
        />
      </Provider>
    </I18nextProvider>
  );
}

export default App;
