import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddAddressIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="8" stroke="#0094FF" strokeWidth="1.2" />
        <path d="M10 6V14" stroke="#0094FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 10H14" stroke="#0094FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default AddAddressIcon;
