import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddressIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.69904 17.3481C9.30861 17.7111 8.70022 17.7077 8.31302 17.3413C7.89966 16.9502 7.50333 16.58 7.12502 16.2266C3.91517 13.2282 2.00293 11.442 2.00293 8.38013C2.00293 4.76103 4.91958 0.999268 9.0029 0.999268C13.0862 0.999268 16.0029 4.76103 16.0029 8.38013C16.0029 11.5276 13.9821 13.3927 10.6084 16.5068C10.3153 16.7772 10.0121 17.0571 9.69904 17.3481ZM11.5029 7.99926C11.5029 9.37996 10.3836 10.4992 9.00292 10.4992C7.62221 10.4992 6.50293 9.37996 6.50293 7.99926C6.50293 6.61855 7.62221 5.49927 9.00292 5.49927C10.3836 5.49927 11.5029 6.61855 11.5029 7.99926Z" fill="#0094FF" />
      </svg>
    </div>
  );
};

export default AddressIcon;
