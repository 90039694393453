import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PostionIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9875 7.17828C12.8353 7.17828 13.6483 7.51506 14.2478 8.11455C14.8473 8.71403 15.1841 9.5271 15.1841 10.3749C15.1841 11.2227 14.8473 12.0358 14.2478 12.6353C13.6483 13.2347 12.8353 13.5715 11.9875 13.5715C11.1397 13.5715 10.3266 13.2347 9.72712 12.6353C9.12764 12.0358 8.79086 11.2227 8.79086 10.3749C8.79086 9.5271 9.12764 8.71403 9.72712 8.11455C10.3266 7.51506 11.1397 7.17828 11.9875 7.17828ZM19.0001 10.3763C19.0001 9.28456 18.7451 8.20791 18.2556 7.23208C17.766 6.25625 17.0554 5.4082 16.1803 4.75545C15.3052 4.1027 14.2898 3.66329 13.2149 3.47221C12.14 3.28112 11.0353 3.34364 9.98889 3.65478C8.94243 3.96593 7.98306 4.5171 7.18719 5.26442C6.39132 6.01174 5.78093 6.93455 5.40462 7.95939C5.0283 8.98422 4.89647 10.0828 5.0196 11.1675C5.14274 12.2523 5.51745 13.2933 6.1139 14.2078L11.3597 21.2021C11.4179 21.319 11.5076 21.4173 11.6186 21.486C11.7296 21.5547 11.8576 21.5911 11.9882 21.5911C12.1187 21.5911 12.2467 21.5547 12.3578 21.486C12.4688 21.4173 12.5584 21.319 12.6167 21.2021L17.8723 14.2078C18.5802 13.1038 19.0001 11.792 19.0001 10.3763Z" fill="#222222" />
      </svg>
    </div>
  );
};

export default PostionIcon;
