import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ShoppingCartIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6397 18.8C12.6212 18.9641 12.5689 19.1225 12.4861 19.2654L11.6001 20.8H24.3999C24.7182 20.8 25.0234 20.9264 25.2484 21.1515C25.4735 21.3765 25.5999 21.6817 25.5999 22C25.5999 22.3182 25.4735 22.6235 25.2484 22.8485C25.0234 23.0736 24.7182 23.2 24.3999 23.2H9.5999C9.43723 23.2 9.27626 23.1669 9.12677 23.1028C8.97728 23.0387 8.84238 22.9449 8.73027 22.827C8.61817 22.7091 8.5312 22.5697 8.47465 22.4172C8.41809 22.2647 8.39314 22.1022 8.4013 21.9398C8.38646 21.7044 8.44164 21.4699 8.5599 21.2658L10.0175 18.7412L9.7959 17.9144C9.74806 17.8183 9.71 17.7177 9.6823 17.614L7.5393 9.61399C7.51176 9.51129 7.49447 9.40611 7.4877 9.29999L6.5623 5.84659L3.7507 5.09319C3.44701 5.00753 3.18928 4.8057 3.03331 4.5314C2.87734 4.2571 2.83569 3.93241 2.91737 3.62763C2.99905 3.32284 3.19749 3.06249 3.46972 2.90295C3.74195 2.7434 4.06607 2.69751 4.3719 2.77519L7.8491 3.70679C8.06398 3.76431 8.25865 3.88043 8.41136 4.04218C8.56407 4.20394 8.66882 4.40495 8.7139 4.62279C8.7265 4.65699 8.7377 4.69199 8.7473 4.72799L9.5169 7.59999H26.9149C27.1603 7.59998 27.4024 7.65642 27.6226 7.76495C27.8427 7.87348 28.0349 8.0312 28.1843 8.22589C28.3337 8.42058 28.4363 8.64704 28.4842 8.88774C28.5321 9.12844 28.524 9.37693 28.4605 9.61399L26.3175 17.614C26.2264 17.9542 26.0255 18.2549 25.7461 18.4693C25.4667 18.6837 25.1243 18.8 24.7721 18.8H12.6397ZM10.7999 29C9.4745 29 8.3999 27.9254 8.3999 26.6C8.3999 25.2746 9.4745 24.2 10.7999 24.2C12.1253 24.2 13.1999 25.2746 13.1999 26.6C13.1999 27.9254 12.1253 29 10.7999 29ZM23.7999 29C22.4745 29 21.3999 27.9254 21.3999 26.6C21.3999 25.2746 22.4745 24.2 23.7999 24.2C25.1253 24.2 26.1999 25.2746 26.1999 26.6C26.1999 27.9254 25.1253 29 23.7999 29Z" fill="white" />
        <path d="M11.7632 19.0617C11.7447 19.2258 11.6924 19.3843 11.6096 19.5271L10.7236 21.0617H23.5234C23.8417 21.0617 24.1469 21.1882 24.372 21.4132C24.597 21.6383 24.7234 21.9435 24.7234 22.2617C24.7234 22.58 24.597 22.8852 24.372 23.1103C24.1469 23.3353 23.8417 23.4617 23.5234 23.4617H8.72343C8.56077 23.4618 8.3998 23.4287 8.25031 23.3646C8.10081 23.3005 7.96591 23.2066 7.85381 23.0887C7.7417 22.9709 7.65473 22.8315 7.59818 22.6789C7.54163 22.5264 7.51667 22.364 7.52483 22.2015C7.50999 21.9661 7.56518 21.7316 7.68343 21.5275L9.14103 19.0029L8.91943 18.1761C8.8716 18.0801 8.83354 17.9794 8.80583 17.8757L6.66283 9.87574C6.63529 9.77304 6.61801 9.66785 6.61123 9.56174L5.68583 6.10834L2.87423 5.35494C2.57054 5.26928 2.31281 5.06745 2.15685 4.79315C2.00088 4.51885 1.95922 4.19416 2.0409 3.88938C2.12258 3.58459 2.32102 3.32424 2.59325 3.1647C2.86549 3.00515 3.18961 2.95925 3.49543 3.03694L6.97263 3.96854C7.18752 4.02606 7.38219 4.14218 7.5349 4.30393C7.68761 4.46569 7.79235 4.6667 7.83743 4.88454C7.85003 4.91874 7.86123 4.95374 7.87083 4.98974L8.64043 7.86174H26.0384C26.2839 7.86172 26.526 7.91817 26.7461 8.0267C26.9662 8.13523 27.1584 8.29295 27.3078 8.48764C27.4572 8.68233 27.5599 8.90879 27.6078 9.14949C27.6557 9.39019 27.6475 9.63868 27.584 9.87574L25.441 17.8757C25.3499 18.216 25.149 18.5166 24.8696 18.731C24.5902 18.9455 24.2478 19.0617 23.8956 19.0617H11.7632ZM9.92343 29.2617C8.59803 29.2617 7.52343 28.1871 7.52343 26.8617C7.52343 25.5363 8.59803 24.4617 9.92343 24.4617C11.2488 24.4617 12.3234 25.5363 12.3234 26.8617C12.3234 28.1871 11.2488 29.2617 9.92343 29.2617ZM22.9234 29.2617C21.598 29.2617 20.5234 28.1871 20.5234 26.8617C20.5234 25.5363 21.598 24.4617 22.9234 24.4617C24.2488 24.4617 25.3234 25.5363 25.3234 26.8617C25.3234 28.1871 24.2488 29.2617 22.9234 29.2617Z" fill="#333333" />
      </svg>
    </div>
  );
};

export default ShoppingCartIcon;
