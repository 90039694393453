import { getIntl } from "@/App";
import { prefix } from ".";
// import { DropdownMenu } from "react-vant";
// import 'react-vant/lib/index.css';

interface IHeaderProps {
  logo: string;
}

const Header: React.FC<IHeaderProps> = (props) => {
  const { logo } = props;

  // const options = [
  //   {
  //     text: '繁體中文',
  //     value: 'zh-HK',
  //   },
  //   {
  //     text: '繁體中文',
  //     value: 'en',
  //   },
  //   {
  //     text: '简体中文',
  //     value: 'zh-CN',
  //   },
  // ]

  return (
    <div
      className={`${prefix}`}
      style={{
        backgroundImage: `url(${logo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={`${prefix}-title`}>{getIntl().common.headerTitle}</div>
      <div className={`${prefix}-language`}>
        <img
          width="20px"
          height="20px"
          src={`https://mb5-stg-cdn.bindo.co/images/country-flag/hk.svg`}
          alt=""
        />
        繁體中文
        {/* <DropdownMenu onChange={ (value) => console.info('===',value)}>
          <DropdownMenu.Item name="language" options={options} />
        </DropdownMenu> */}
      </div>
    </div>
  )
};

export default Header;