import React from 'react';
import type { IIconProps } from '@/types/common-types';

const NoAddressIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10190_359247)">
          <path d="M40.5818 0C18.4959 0 0.5 17.9592 0.5 40C0.5 40.8163 0.990797 41.6327 1.64519 42.1224C2.46319 42.6122 3.28119 42.6122 4.09918 42.1224L15.5511 35.5918C16.6963 34.9388 17.1871 33.4694 16.5327 32.3265C15.8783 31.1837 14.4059 30.6939 13.2607 31.3469L5.73517 35.5918C7.86196 18.2857 22.7495 4.89796 40.5818 4.89796C60.0501 4.89796 75.7556 20.5714 75.7556 40C75.7556 59.4286 60.0501 75.102 40.5818 75.102C28.9663 75.102 18.1687 69.3878 11.4611 59.7551C10.6431 58.6122 9.17076 58.2857 8.02556 59.102C6.88037 59.9184 6.55317 61.3878 7.37117 62.5306C14.8967 73.4694 27.1667 80 40.4182 80C62.5041 80 80.5 62.0408 80.5 40C80.5 17.9592 62.6677 0 40.5818 0Z" fill="#0094FF" />
          <path d="M54.5731 37.1582C56.371 37.1582 57.5151 36.0096 57.5151 34.2046L57.5151 28.9536C57.5151 27.1486 56.371 26 54.5731 26C53.429 26 51.6311 27.1486 51.6311 28.9536L51.6311 34.2046C51.6311 36.0096 52.7752 37.1582 54.5731 37.1582ZM24.4998 28.9537L24.4998 34.2046C24.4998 36.0096 25.6439 37.1582 27.4418 37.1582C29.2397 37.1582 30.3838 36.0096 30.3838 34.2046L30.3838 28.9537C30.3838 27.1487 29.2397 26 27.4418 26C25.6439 26 24.4998 27.1487 24.4998 28.9537ZM54.5736 48.0273C55.7177 46.8787 55.7177 45.0737 54.5736 43.925C53.9198 43.2687 53.4295 43.2686 52.7757 43.2686C52.122 43.2686 51.6316 43.2687 50.9778 43.925C45.7476 49.1759 36.2678 49.8323 31.0376 43.925C29.8934 42.7764 28.0956 42.7764 26.9514 43.925C25.8073 45.0737 25.8073 46.8787 26.9514 48.0273C30.5472 51.6373 35.7775 53.9346 41.0077 53.9346C46.2379 53.9346 51.6316 51.6373 54.5736 48.0273Z" fill="#0094FF" />
        </g>
        <defs>
          <clipPath id="clip0_10190_359247">
            <rect width="80" height="80" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default NoAddressIcon;
