import React from 'react';
import type { IIconProps } from '@/types/common-types';

const PickUpIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6071 8.99996C16.6071 13.2013 13.2013 16.6071 8.99996 16.6071C4.79866 16.6071 1.39282 13.2013 1.39282 8.99996C1.39282 4.79866 4.79866 1.39282 8.99996 1.39282C13.2013 1.39282 16.6071 4.79866 16.6071 8.99996Z" stroke="#0094FF" strokeWidth="1.5" />
        <path d="M8.33333 4C8.79357 4 9.16667 4.39175 9.16667 4.875V9.24956L11.6667 9.25C12.1269 9.25 12.5 9.64175 12.5 10.125C12.5 10.6082 12.1269 11 11.6667 11L8.34385 10.9999C8.34035 11 8.33684 11 8.33333 11C7.8731 11 7.5 10.6082 7.5 10.125V4.875C7.5 4.39175 7.8731 4 8.33333 4Z" fill="#0094FF" />
      </svg>
    </div>
  );
};

export default PickUpIcon;
