import { getIntl } from "@/App"
import { prefix } from "."
import ShoppingCartIcon from "../svg/ShoppingCartIcon"
import { formatCurrency, plus } from "@/utils";
import { IShoppingCartInfo } from "@/types/order-types";

interface IShoppingCartBarProps {
  shoppingCartData: IShoppingCartInfo | undefined;
  setShowShoppingCartPopup: (value: boolean) => void;
}

const ShoppingCartBar: React.FC<IShoppingCartBarProps> = (props) => {
  const { shoppingCartData, setShowShoppingCartPopup } = props;
  const { favoriteIdLineItemRef = {} } = shoppingCartData || {};

  let price = 0;
  let totalQuanity = 0;
  Object.values(favoriteIdLineItemRef).forEach(item => {
    const { quantity = 0, total = '0' } = item;
    totalQuanity = plus(totalQuanity, quantity);
    if (!isNaN(Number(total))) {
      price = plus(price, Number(total), 2);
    };
  })

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}-content`}>
        <div className={`${prefix}-content-left`}>
          <div className={`${prefix}-icon`}>
            <div className={`${prefix}-icon-quanity`}>{totalQuanity}</div>
            <ShoppingCartIcon />
          </div>
          <div className={`${prefix}-total`}>
            {formatCurrency(Number(price))}
          </div>
        </div>
        <div className={`${prefix}-content-right`} onClick={() => setShowShoppingCartPopup(true)}>{getIntl().common.checkout}</div>
      </div>
    </div>
  )
}

export default ShoppingCartBar