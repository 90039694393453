import React from 'react';
import type { IIconProps } from '@/types/common-types';

const TakeawayIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5517 6.72065C10.5838 5.12135 11.0255 4.29419 11.4455 3.86678C11.8885 3.416 12.4147 3.29995 12.7499 3.29995C13.0851 3.29995 13.6113 3.416 14.0543 3.86678C14.4742 4.29419 14.916 5.12135 14.948 6.72065H10.5517ZM8.95149 6.72065C8.98421 4.81973 9.51599 3.5476 10.3043 2.74538C11.1113 1.92405 12.0851 1.69995 12.7499 1.69995C13.4147 1.69995 14.3885 1.92405 15.1955 2.74538C15.9838 3.5476 16.5156 4.81973 16.5483 6.72065H16.8512C18.6166 6.72065 20.101 8.04536 20.3011 9.79943L21.5294 20.567C21.6469 21.5971 20.8412 22.5 19.8044 22.5H5.69536C4.65856 22.5 3.85288 21.5971 3.97039 20.567L5.19868 9.79943C5.39878 8.04536 6.88319 6.72065 8.64863 6.72065H8.95149ZM16.8512 8.32065H8.64863C7.69668 8.32065 6.89627 9.03496 6.78837 9.98077L5.56008 20.7484C5.55086 20.8291 5.61405 20.9 5.69536 20.9H19.8044C19.8857 20.9 19.9489 20.8291 19.9397 20.7484L18.7114 9.98077C18.6035 9.03496 17.8031 8.32065 16.8512 8.32065ZM9.58264 10.655C9.89506 10.3425 10.4016 10.3425 10.714 10.655L10.8301 10.7711C11.8737 11.8147 13.5658 11.8147 14.6094 10.7711L14.7255 10.655C15.0379 10.3425 15.5445 10.3425 15.8569 10.655C16.1693 10.9674 16.1693 11.4739 15.8569 11.7863L15.7407 11.9025C14.0723 13.5709 11.3672 13.5709 9.69877 11.9025L9.58264 11.7863C9.27022 11.4739 9.27022 10.9674 9.58264 10.655Z" fill="currentColor" />
      </svg>
    </div>
  );
};

export default TakeawayIcon;
