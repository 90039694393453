import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from './config';
import Cookies from 'js-cookie';
import { Constants } from '@/constants';
import { createUuid } from '@/utils';

interface IAxiosRequestConfig<D = any> extends AxiosRequestConfig {
  httpStatusCallback?: (status: number) => void;
  data?: D;
}

const instance = axios.create({
  baseURL: Config.baseUrl,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {

    const { headers = {} } = config;
    const token = Cookies.get(Constants.TOKEN) || '';

    config = {
      ...config,
      headers: {
        'X-Request-Id': createUuid(),
        'x-i18n-lang': 'en-US',
        'X-USER-ACCESS-TOKEN': token,
        "Signature": "good",
        "X-Skip-Signature": "TRUE",
        ...headers
      }
    }
    return config;
  },
  (error) => {
    console.info('------request error');
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data || {};
  },
  (error) => {
    const { response, config } = error || {};

    if (response) {
      const { status } = response;

      if (config && config.httpStatusCallback) {
        config.httpStatusCallback(status)
      } else {
 
      }
    } else {

    }
    console.info('------response error');
    return Promise.reject(error);
  }
);

export const get = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: IAxiosRequestConfig<D>
): Promise<R> => {
  return instance.get(url, config);
};

export const post = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: IAxiosRequestConfig<D>
): Promise<R> => {
  return instance.post(url, data, config);
};

export const put = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: IAxiosRequestConfig<D>
): Promise<R> => {
  return instance.put(url, data, config);
};

export const patch = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data?: D,
  config?: IAxiosRequestConfig<D>
): Promise<R> => {
  return instance.patch(url, data, config);
};

export const remove = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config?: IAxiosRequestConfig<D>
): Promise<R> => {
  return instance.delete(url, config);
};

const api = {
  get,
  post,
  put,
  patch,
  remove,
};

export default api;

export interface ResponseBody {
  data: any;
  code: number;
  message: string;
}
