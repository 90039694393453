import api from "@/libs/api";
import Config from "@/libs/config";
import { ITable } from "@/types/table-types";

export const queryTableList = async (businessID: string): Promise<ITable[] | undefined> => {
  let list: ITable[] = [];
  try {
    const resp = await api.get(`${Config.urls.smartpos}/public/api/v1/device/tables`, {
      headers: {
        'x-p-business-id': businessID,
      }
    });

    if (resp && resp.data && Array.isArray(resp.data.tables)) {
      list = resp.data.tables;
    }
  } catch (error) {
    console.error(error);
    return list;
  }

  return list;
};

export const queryTableDetails = async (businessID: string, id: string): Promise<ITable | undefined> => {
  let record: ITable | undefined = undefined;
  try {
    const resp = await api.get(`${Config.urls.smartpos}/public/api/v1/device/tables/${id}`, {
      headers: {
        'x-p-business-id': businessID,
      }
    });

    if (resp && resp.data && resp.data.table && resp.data.table.id) {
      record = resp.data.table;
    }
  } catch (error) {
    console.error(error);
    return record;
  }

  return record;
};