import { Input } from 'react-vant'
import { prefix } from '.';
import { getIntl } from '@/App';
import { useState } from 'react';
import { IObject } from '@/types/common-types';
import { queryGoogleService } from '@/interface/google';
import PostionIcon from '../svg/PostionIcon';

interface IAddressSearchProps {
  onChange: (value: string, lat: string, lng: string) => void;
};

interface IOption {
  value: string;
  label: string;
  details: string;
}

const AddressSearch: React.FC<IAddressSearchProps> = (props) => {
  const { onChange } = props;

  const [options, setOptions] = useState<IOption[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const handleValueChange = async (value: any) => {
    setSearchValue(value);
    const { predictions = [] } = await queryGoogleService({
      query: `region=HK&input=${value}`,
      action: 'autocomplete',
    });
    const options: IOption[] = [];
    predictions.forEach((item: IObject) => {
      const { structured_formatting = {}, description = '', place_id } = item;
      const { main_text = '' } = structured_formatting;
      const newItem = {
        value: place_id,
        label: main_text,
        details: description,
      };
      options.push(newItem);
    });
    setOptions(options);
  };

  const handleHightlight = (value: string) => {
    let returnValue = value;
    if (value && searchValue) {
      const index = value
        .toLocaleLowerCase()
        .indexOf(searchValue.toLocaleLowerCase());
      const hightStr = value.slice(index, index + searchValue.length);
      returnValue = value.replace(
        hightStr,
        `<span style="color: #10B981">${hightStr}</span>`
      );
    }
    return returnValue;
  };

  const handleAddressItemClick = async (option: IOption) => {
    const { result = {} } = await queryGoogleService({
      query: `place_id=${option.value}`,
      action: 'details',
    });
    const { location = {} } = result.geometry || {};
    const { lat = '', lng = '' } = location;
    onChange(option.details, lat, lng);
  }

  return (
    <div className={`${prefix}-search`}>
      <div className={`${prefix}-search-label`}>{getIntl().common.addressLabel}</div>
      <Input onChange={handleValueChange} placeholder={getIntl().common.search} />
      <div className={`${prefix}-search-content ${options.length > 0 ? 'active' : ''}`}>
        {
          options.map(item => (
            <div
              key={item.value}
              className={`${prefix}-search-content-item`}
              onClick={() => handleAddressItemClick(item)}
            >
              <PostionIcon />
              <div className={`${prefix}-search-content-item-right`}>
                <div
                  className={`${prefix}-search-content-item-right-label`}
                  dangerouslySetInnerHTML={{
                    __html: handleHightlight(item.label),
                  }}
                ></div>
                <div className={`${prefix}-search-content-item-right-details`}>{item.details}</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default AddressSearch;