import { produce } from 'immer';
import ActionType from '@/actions/action-type';
import { IClearShoppingCart, ISetShoppingCart } from '@/actions/shopping-cart-action';
import { IShoppingCartInfo } from '@/types/order-types';
import { handleActions } from 'redux-actions';
import { getLocalStorageJson, setLocalStorageJson } from '@/utils';
import { Constants } from '@/constants';

export interface IShoppingCartState {
  shoppingCartData: {
    [storeId: string] : IShoppingCartInfo,
  };
}

const actions: any = {};

actions[ActionType.SET_SHOPPING_CART_DATA] = (state: IShoppingCartState, action: ISetShoppingCart) => produce(state, draft => {
  if (action.data) {
    draft.shoppingCartData = action.data;
    setLocalStorageJson(Constants.SHOPPING_CART_DATA, action.data);
  }
});

actions[ActionType.CLEAR_SHOPPING_CART] = (state: IShoppingCartState, action: IClearShoppingCart) => produce(state, draft => {
  const localShoppingCart: { [storeId: string] : IShoppingCartInfo } = getLocalStorageJson(Constants.SHOPPING_CART_DATA);
  delete localShoppingCart[action.storeID];
  delete draft.shoppingCartData[action.storeID];
  setLocalStorageJson(Constants.SHOPPING_CART_DATA, localShoppingCart)
}) 

const initState: IShoppingCartState = {
  shoppingCartData: {},
};

export default handleActions(actions, initState);