import React from 'react';
import type { IIconProps } from '@/types/common-types';

const AddIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.638672" y="0.5" width="19" height="19" rx="9.5" fill="#0094FF" stroke="#0094FF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.63867 14C9.63867 14.2761 9.86253 14.5 10.1387 14.5C10.4148 14.5 10.6387 14.2761 10.6387 14V10.5H14.1387C14.4148 10.5 14.6387 10.2761 14.6387 10C14.6387 9.72386 14.4148 9.5 14.1387 9.5H10.6387V6C10.6387 5.72386 10.4148 5.5 10.1387 5.5C9.86253 5.5 9.63867 5.72386 9.63867 6V9.5H6.13867C5.86253 9.5 5.63867 9.72386 5.63867 10C5.63867 10.2761 5.86253 10.5 6.13867 10.5H9.63867V14Z" fill="white" />
      </svg>
    </div>
  );
};

export default AddIcon;
