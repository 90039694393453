import { Button, Form, Input, Picker, Radio } from "react-vant";
import { prefix } from ".";
import { getIntl } from "@/App";
import ArrowDownIcon from "../svg/ArrowDownIcon";
import { useEffect, useMemo, useState } from "react";
import ArrowClickIcon from "../svg/ArrowClickIcon";
import { getCascadeData, getDefaultPickerValues } from "@/utils/date-utils";

interface IAddressInputProps {
  value?: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
};

const TimeInput: React.FC<IAddressInputProps> = (props) => {
  const { value, onClick } = props;
  return (
    <div onClick={onClick} className={`${prefix}-input flex`}>
      <span>{value}</span>
      <ArrowClickIcon />
    </div>
  )
};


interface ITakeawayProcessProps {
  selectKey: string;
  showPickTime: boolean;
  onConfirm: (option?: any) => void;
  setSelectIndex: (value: string) => void;
  setShowPickTime: (value: boolean) => void;
  setShowTabs: (value: boolean) => void;
  setTitle: (value: string) => void;
};

const TakeawayProcess: React.FC<ITakeawayProcessProps> = (props) => {
  const {
    selectKey,
    showPickTime,
    onConfirm,
    setShowTabs,
    setTitle,
    setShowPickTime,
    setSelectIndex,
  } = props;

  const [form] = Form.useForm();
  const phonePrefix = '+852';

  const [cascaderData, setCascaderData] = useState<any[]>([]);
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    if (selectKey === '2') {
      setCascaderData(getCascadeData());
      setValue(getDefaultPickerValues());
    }
  }, [selectKey]);

  const handleChange = (values: any) => {
    const [selectedDate] = values;

    if (selectedDate !== value[0]) {
      let defaultAmPm = 'AM';
      const now = new Date();
      const currentHour = now.getHours();
      let defaultKey = 1;

      if (selectedDate === 'Today' && currentHour >= 12) {
        defaultAmPm = 'PM';
        defaultKey = 0;
      }

      const newSelectedTime = cascaderData[defaultKey].children[0].value;
      const newValues = [selectedDate, newSelectedTime, defaultAmPm];
      setValue(newValues);
      return;
    }

    setValue(values);
  };

  const onFinish = (values: any) => {
    if (showPickTime) {
      let value = 'Immediately';

      if (selectKey === '2') {
        value = getValueDispaly()
      }
      setShowPickTime(false);
      form.setFieldValue('delivery_date', value);
    } else {
      onConfirm(values)
    }
  };

  const handleShowPickTime = () => {
    setShowPickTime(true);
    setShowTabs(false);
    setTitle(getIntl().common.pickUpTime);
  };

  const getValueDispaly = () => `${value[0] || ''} ${value[1] || ''}${value[2] || ''}`;

  const valueDispay = useMemo(() => {
    return getValueDispaly();
    // eslint-disable-next-line
  }, [value]);

  const options = [
    {
      label: getIntl().common.immediately,
      name: '1',
    },
    {
      label: getIntl().common.scheduleForLater,
      name: '2',
    },
  ];

  return (
    <div className={`${prefix}-takeaway`}>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
      >
        {
          !showPickTime &&
          <>
            <Form.Item
              rules={[{ required: true, message: getIntl().common.receiverNameRequired }]}
              name='name'
              label={getIntl().common.receiverName}
            >
              <Input placeholder={getIntl().common.receiverNamePlaceholder} />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: getIntl().common.phoneNumberRequired }]}
              name='phone'
              label={getIntl().common.phoneNumber}
            >
              <div className={`${prefix}-delivery-phone`}>
                <img
                  width="20px"
                  height="20px"
                  src={`https://mb5-stg-cdn.bindo.co/images/country-flag/hk.svg`}
                  alt=""
                />
                <div className={`${prefix}-delivery-phone-number`}>{phonePrefix}</div>
                <ArrowDownIcon className={`${prefix}-delivery-phone-icon`} />
                <Input placeholder={getIntl().common.phoneNumberPlaceholder} />
              </div>
            </Form.Item>
            <Form.Item
              initialValue={"Immediately"}
              name='delivery_date'
              label={getIntl().common.pickUpTime}
            >
              <TimeInput onClick={handleShowPickTime} />
            </Form.Item>
          </>
        }
        {
          showPickTime &&
          <div className={`${prefix}-takeaway-selectTime`}>
            <Radio.Group onChange={(name) => setSelectIndex(name)} defaultValue="1">
              {options.map(option => <Radio key={option.name} name={option.name}>{option.label}</Radio>)}
            </Radio.Group>
            {
              selectKey === '2' &&
              <div className={`${prefix}-takeaway-selectTime-content`}>
                <div className={`${prefix}-takeaway-selectTime-title`}>
                  <div>{getIntl().common.chooseTime}</div>
                  <div className="value">{valueDispay}</div>
                </div>
                <Picker
                  value={value}
                  showToolbar={false}
                  columns={cascaderData}
                  onChange={handleChange}
                />
              </div>
            }
          </div>
        }
        <Button nativeType='submit' type='primary'>{getIntl().common.save}</Button>
      </Form>
    </div>
  )
};

export default TakeawayProcess;