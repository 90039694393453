export const getDateArray = (num: number = 7) => {
  const dateArray = [];
  const now = new Date();
  for (let i = 0; i < num; i++) {
    const futureDate = new Date();
    futureDate.setDate(now.getDate() + i);

    let formattedDate;
    if (i === 0) {
      formattedDate = 'Today';
    } else if (i === 1) {
      formattedDate = 'Tomorrow';
    } else {
      formattedDate = futureDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
    }

    dateArray.push({ text: formattedDate, value: formattedDate });
  }
  return dateArray;
};

export const getTimeArray = (isToday: boolean) => {
  const now = new Date();
  const timeSlots = [];
  const startHour = 0;
  const endHour = 12;
  let startTime = new Date();
  startTime.setHours(startHour, 0, 0, 0);

  if (isToday) {
    const currentTimeRounded = new Date();
    currentTimeRounded.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
    currentTimeRounded.setHours(currentTimeRounded.getHours() % 12 || 12);
    startTime = currentTimeRounded;
  }

  // 生成每15分钟的时间点
  while (startTime.getHours() < endHour || (startTime.getHours() === endHour && startTime.getMinutes() === 0)) {
    let hours = startTime.getHours();
    let minutes = startTime.getMinutes();
    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes}`;
    timeSlots.push({ text: formattedTime, value: formattedTime });
    startTime.setMinutes(startTime.getMinutes() + 15);
  }
  
  return timeSlots;
};

export const getAmPmArray = (isToday: boolean, currentTime: number) => {
  const amPmArray = [];

  if (isToday && currentTime >= 12) {
    amPmArray.push({ text: 'PM', value: 'PM' });
  } else {
    amPmArray.push({ text: 'AM', value: 'AM' }, { text: 'PM', value: 'PM' });
  }

  return amPmArray;
};

export const getDefaultPickerValues = () => {
  const now = new Date();
  const currentTimeRounded = new Date();
  currentTimeRounded.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);

  let hours = currentTimeRounded.getHours();
  let minutes = currentTimeRounded.getMinutes();
  

  const isPM = hours >= 12;
  
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes}`;
  
  return ['Today', formattedTime, isPM ? 'PM' : 'AM'];
};

export const getCascadeData = () => {
  const dateArray = getDateArray();

  const currentTime = new Date().getHours();

  return dateArray.map((dateItem) => {
    const isToday = dateItem.value === 'Today';

    const children = getTimeArray(isToday).map((timeItem) => ({
      text: timeItem.text,
      value: timeItem.value,
      children: getAmPmArray(isToday, currentTime),
    }));

    return {
      text: dateItem.text,
      value: dateItem.value,
      children: children,
    }
  });
};

export const getFutureDate = (n: number) => {
  const currentDate = new Date();
  const preDate = new Date(currentDate.getTime() + n * 24 * 3600 * 1000);
  const y = preDate.getFullYear();
  const m = preDate.getMonth() + 1;
  const d = preDate.getDate();
  const s = y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
  return s;
};