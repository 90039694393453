import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ScanCodeFailedIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22C1 17.9668 2.13702 14.1991 4.108 11C7.80475 4.99985 14.4353 1 22 1C33.598 1 43 10.402 43 22Z" fill="#FC2E01" />
        <path d="M21.9999 43.7992C21.5999 43.7992 21.1999 43.4992 21.1999 42.9992C21.1999 42.4992 21.4999 42.1992 21.9999 42.1992C33.1999 42.1992 42.1999 33.0992 42.1999 21.9992C42.1999 10.8992 33.1999 1.79922 21.9999 1.79922C14.8999 1.79922 8.49992 5.39922 4.69992 11.3992C4.49992 11.7992 3.99992 11.8992 3.69992 11.5992C3.29992 11.3992 3.3059 10.9132 3.49992 10.5992C7.49992 4.09922 14.3999 0.199219 21.9999 0.199219C33.9999 0.199219 43.7999 9.99922 43.7999 21.9992C43.7999 33.9992 33.9999 43.7992 21.9999 43.7992Z" fill="black" />
        <path d="M29.0999 29.7992C28.8999 29.7992 28.6999 29.6992 28.5999 29.5992L14.3999 15.4992C14.0999 15.1992 14.0999 14.6992 14.3999 14.3992C14.6999 14.0992 15.1999 14.0992 15.4999 14.3992L29.5999 28.4992C29.8999 28.7992 29.8999 29.2992 29.5999 29.5992C29.4999 29.6992 29.2999 29.7992 29.0999 29.7992Z" fill="black" />
        <path d="M14.8999 29.7992C14.6999 29.7992 14.4999 29.6992 14.3999 29.5992C14.0999 29.2992 14.0999 28.7992 14.3999 28.4992L28.4999 14.3992C28.7999 14.0992 29.2999 14.0992 29.5999 14.3992C29.8999 14.6992 29.8999 15.1992 29.5999 15.4992L15.4999 29.5992C15.2999 29.6992 15.0999 29.7992 14.8999 29.7992Z" fill="black" />
      </svg>
    </div>
  );
};

export default ScanCodeFailedIcon;

