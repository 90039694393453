import { IFavoriteSection, IMenuData } from "@/types/menu-types";
import { prefix } from ".";
import { useEffect, useState } from "react";
import TabsItems from "./TabsItems";

interface ITabsProps {
  data: IMenuData[];
  activeKes: string[];
  setActiveKes: (value: string[]) => void;
}

const Tabs: React.FC<ITabsProps> = (props) => {
  const { data, activeKes, setActiveKes } = props;

  const [favoriteSection, setFavoriteSection] = useState<IFavoriteSection[]>([]);

  useEffect(() => {
    if (data.length > 0) {
      const defaultSection = data[0].favorite_section;
      setFavoriteSection(defaultSection);
    }
  }, [data])

  const key = activeKes[0];

  const handleItemClick = (record: IMenuData) => {
    const { favorite_section } = record;
    const secondKey = favorite_section[0]?.id || '';
    const newActiveKes = [`${record.id}`, `${secondKey}`];
    setActiveKes(newActiveKes);
    setFavoriteSection(record.favorite_section);
  }

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}-content`}>
        {
          data.map(item => (
            <div onClick={() => handleItemClick(item)} className={`${prefix}-content-item ${key === `${item.id}` ? 'active' : ''}`} key={item.id}>{item.name}</div>
          ))
        }
      </div>
      <TabsItems
        data={favoriteSection}
        activeKes={activeKes}
        setActiveKes={setActiveKes}
      />
    </div>
  )
};

export default Tabs;