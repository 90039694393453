import React from 'react';
import type { IIconProps } from '@/types/common-types';

const SuccessIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.9999 22.5C41.9999 33.8218 32.8218 42.9999 21.5 42.9999C10.1781 42.9999 1 33.8218 1 22.5C1 18.5627 2.10994 14.8848 4.03399 11.7619C7.64272 5.90461 14.1154 2 21.5 2C32.8218 2 41.9999 11.1781 41.9999 22.5Z" fill="#0094FF" />
        <path d="M21.5002 42.9999C32.822 42.9999 42.0001 33.8218 42.0001 22.5C42.0001 11.1781 32.822 2 21.5002 2C14.1155 2 7.64291 5.90461 4.03418 11.7619" stroke="black" stroke-width="1.30158" stroke-linecap="round" />
        <path d="M10.4365 21.849L18.246 29.6585L32.2381 15.6665" stroke="black" stroke-width="1.30158" stroke-linecap="round" />
      </svg>
    </div>
  );
};

export default SuccessIcon;

