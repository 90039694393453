import { ILineItem, IOrderParams, IShoppingCartInfo, PurchasableType } from "@/types/order-types"
import { createUuid } from "."
import { IListing } from "@/types/menu-types"
import { IDeliveryAddress, IDiningMethods, IPickUpOption } from "@/types/common-types";
import { ITable } from "@/types/table-types";
import { initialDelivery } from "@/constants";
import dayjs from "dayjs";

export const getDefaultLineItem = (listing: IListing) => {
  const item: ILineItem = {
    uuid: createUuid(),
    purchasable_type: PurchasableType.LISTING,
    purchasable_id: `${listing.id}`,
    price: `${listing.price}`,
    quantity: 1,
    total: `${listing.price}`,
    label: listing.name,
  }
  return item;
};

interface IGetOrderByShoppingCartParams {
  type: IDiningMethods;
  shoppingCartData: IShoppingCartInfo;
  table?: ITable;
  deliveryAddress?: IDeliveryAddress;
  pickUpOption?: IPickUpOption;
}

export const getOrderByShoppingCart = (params: IGetOrderByShoppingCartParams) => {
  const { type, shoppingCartData, table, deliveryAddress, pickUpOption } = params;
  const { favoriteIdLineItemRef } = shoppingCartData;
  const values = Object.values(favoriteIdLineItemRef);

  let order: IOrderParams = {
    order: {
      currency: 'HKD',
      reference_number: `W-${new Date()
        .getTime()
        .toString()
        .substring(0, 12)}`,
      shipping_method: type,
      line_items: values,
    }
  };

  if (type === IDiningMethods.DELIVERY && deliveryAddress) {
    order.delivery_note = deliveryAddress;
    order.order.initial_delivery = initialDelivery;
  } else if (type === IDiningMethods.DINE_IN) {
    order.party = {
      id: createUuid(),
      table_id: table?.id || '',
      table_name: table?.name || '',
      number_of_people: table?.seats || 1,
      seated_at: dayjs().format(),
      status: 'ordered',
    };
  } else if (type === IDiningMethods.TAKEAWAY && pickUpOption) {
    order.delivery_note = {
      ...pickUpOption
    }
  }

  return order;
}