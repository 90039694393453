import { useEffect } from "react";
import { prefix } from ".";


interface IToastProps {
  show: boolean;
  message: string;
  icon: React.ReactNode;
  setShow: (value: boolean) => void;
};

let toastTimeout: any;

const Toast: React.FC<IToastProps> = (props) => {
  const { icon, message, show, setShow } = props;

  useEffect(() => {
    if (show) {
      clearTimeout(toastTimeout);
      toastTimeout = setTimeout(() => {
        setShow(false);
      }, 3000);
    };

    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      {
        show &&
        <div className={`${prefix}`}>
          <div className={`${prefix}-content`}>
            <div className={`${prefix}-icon`}>{icon}</div>
            <div className={`${prefix}-message`}>{message}</div>
          </div>
        </div>
      }
    </>
  )
};

export default Toast;