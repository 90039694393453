import MenuList from "@/components/MenuList";
import { IFavorite, IMenuData } from "@/types/menu-types";
import { prefix } from ".";
import Header from "@/components/Header";
import { useParams, useSearchParams } from "react-router-dom";
import { IReducersState } from "@/reducers";
import { useSelector } from "react-redux";
import Business from "@/components/Business";
import { useEffect, useMemo, useState } from "react";
import { queryMenus } from "@/interface/menu";
import Tabs from "@/components/Tabs";
import ShoppingCartBar from "@/components/ShoppingCartBar";
import DiningMethods from "@/components/DiningMethods";
import { getIntl } from "@/App";
import { IDeliveryAddress, IDiningMethods, IPickUpOption } from "@/types/common-types";
import { getLocalStorageJson, setLocalStorageJson } from "@/utils";
import { Constants } from "@/constants";
import DiningMethodsPanel from "@/components/DiningMethodsPanel";
import { queryTableDetails } from "@/interface/table";
import { ITable } from "@/types/table-types";
import ActionType from "@/actions/action-type";
import { IDispatch } from '@/actions';
import { useDispatch } from "react-redux";
import { IShoppingCartInfo } from "@/types/order-types";
import ShoppingCartPopup from "@/components/ShoppingCartPopup";
import Toast from "@/components/Toast";
import SuccessIcon from "@/components/svg/SuccessIcon";
import { getOrderByShoppingCart } from "@/utils/order-utils";
import { overbooking } from "@/interface/order";
import BusinessInfoPanel from "@/components/BusinessInfoPanel";

const MenuListPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch: IDispatch = useDispatch();
  const { business_id: businessID = '' } = useParams();
  const tableId = searchParams.get('table') || '';
  const { common, cart } = useSelector((state: IReducersState) => state);
  const { business } = common;
  const { shoppingCartData: shoppingCartDataState } = cart;
  const { types = [] } = business;
  const [menusData, setMenusData] = useState<IMenuData[]>([]);
  const [activeKes, setActiveKes] = useState<string[]>([]);
  const [data, setData] = useState<IFavorite[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [methodTitle, setMethodTitle] = useState<string>('');
  const [addressList, setAddressList] = useState<IDeliveryAddress[]>([]);
  const [showOption, setShowOption] = useState<boolean>(false);
  const [diningMethod, setDiningMethod] = useState<IDiningMethods>();
  const [diningMethods, setDiningMethods] = useState<IDiningMethods[]>([]);
  const [tableDetails, setTableDetails] = useState<ITable>();
  const [pickUpOption, setPickUpOption] = useState<IPickUpOption | undefined>();
  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCartInfo>();
  const [showShoppingCartPopup, setShowShoppingCartPopup] = useState<boolean>(false);
  const [cartList, setCartList] = useState<IFavorite[]>([]);
  const [showTips, setShowTips] = useState<boolean>(false);

  useEffect(() => {
    if (business.id) {
      loadRecords();
    }
    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    setShoppingCartData(shoppingCartDataState[business.storeID]);
    // eslint-disable-next-line
  }, [shoppingCartDataState]);

  useEffect(() => {
    const { favoriteIdLineItemRef = {} } = shoppingCartData || {};
    const listings = Object.keys(favoriteIdLineItemRef);
    if (showShoppingCartPopup) {
      const records: IFavorite[] = [];
      menusData.forEach(item => {
        item.favorite_section.forEach(record => {
          record.favorites.forEach(favorite => {
            if (listings.includes(`${favorite.id}`)) {
              records.push(favorite);
            }
          })
        })
      });
      setCartList(records);
    }
    // eslint-disable-next-line
  }, [showShoppingCartPopup]);

  useEffect(() => {
    if (menusData.length > 0 && activeKes.length > 1) {
      const menu = menusData.filter(item => `${item.id}` === activeKes[0])[0];

      if (menu) {
        let favorites: IFavorite[] = [];
        let isFirst = false;
        menu.favorite_section.forEach((item, index) => {
          if (`${item.id}` === activeKes[1]) {
            favorites = item.favorites;
            isFirst = index === 0;
          }
        });
        setIsFirst(isFirst);
        setData(favorites);
      }
    }
  }, [activeKes, menusData]);

  const loadRecords = async () => {

    const menus = await queryMenus(businessID);
    if (menus && menus.length > 0) {
      menus.sort((a, b) => a.position - b.position);
      setMenusData(menus);
      const firstKey = `${menus[0].id}`;
      const favoriteSection = menus[0].favorite_section
      const secondKey = `${favoriteSection[0].id}`;
      setActiveKes([firstKey, secondKey])
    }

    let title = '';
    const addresses: IDeliveryAddress[] = getLocalStorageJson(Constants.DELIVERY_ADDRESS) || [];
    let type = undefined;
    let newTypes = [...types];

    if (tableId) {
      // const tables = await queryTableList(businessID);
      // console.info('====tables', tables);
      const tableDetails = await queryTableDetails(businessID, tableId);
      type = IDiningMethods.DINE_IN;
      if (tableDetails) {
        setTableDetails(tableDetails);
        setShowOption(true);
      } else {
        title = getIntl().common.selectService;
      };

      const target = newTypes.indexOf(IDiningMethods.DINE_IN);

      if (target >= 0) {
        const [item] = newTypes.splice(target, 1);
        newTypes.unshift(item);
      } else {
        newTypes.unshift(IDiningMethods.DINE_IN);
      }
    } else {
      if (types.includes(IDiningMethods.TAKEAWAY)) {
        type = IDiningMethods.TAKEAWAY;
        title = getIntl().common.selectService;
        const target = newTypes.indexOf(IDiningMethods.TAKEAWAY);
        if (target >= 0) {
          const [item] = newTypes.splice(target, 1);
          newTypes.unshift(item);
        }
      } else {
        if (types.includes(IDiningMethods.DELIVERY)) {
          type = IDiningMethods.DELIVERY;
          if (addresses.length > 0) {
            setShowOption(true);
          } else {
            title = getIntl().common.noDeliveryTips;
          }
        }
      }
    };

    if (newTypes.length > 1) {
      newTypes = newTypes.splice(0, 2);
    };

    setDiningMethod(type);
    setAddressList(addresses);
    setMethodTitle(title);
    setDiningMethods(newTypes);
  };

  const handleConfirm = (option?: any) => {
    switch (diningMethod) {
      case IDiningMethods.DELIVERY:
        if (option) {
          const newAddress = [...addressList];
          newAddress.unshift(option);
          setAddressList(newAddress);
          setLocalStorageJson(Constants.DELIVERY_ADDRESS, newAddress);
          if (newAddress.length > 0 && !showOption) {
            setShowOption(true);
          }
        }
        break;
      case IDiningMethods.TAKEAWAY:
        if (option) {
          setPickUpOption(option);
          setShowOption(true);
        }
        break;
      case IDiningMethods.DINE_IN:
        if (option) {
          setTableDetails(option);
          setShowOption(true);
          setShowTips(true);
        }
        break;
      default:
        break;
    };
    setShowOption(true)
    setShowPopup(false);
  };

  const handleChangeAddress = (n: number) => {
    if (n < 0 || n >= addressList.length) return;
    const newList = [...addressList];
    const item = newList.splice(n, 1)[0];
    newList.unshift(item);
    setAddressList(newList);
    setShowPopup(false);
  };

  const changeType = (type: IDiningMethods) => {
    switch (type) {
      case IDiningMethods.DELIVERY:
        if (addressList.length < 1) {
          setShowOption(false);
          setMethodTitle(getIntl().common.noDeliveryTips);
        }
        break;
      case IDiningMethods.TAKEAWAY:
        if (!pickUpOption) {
          setShowOption(false);
          setMethodTitle(getIntl().common.selectService);
        }
        break;
      case IDiningMethods.DINE_IN:
        if (!tableDetails) {
          setShowOption(false);
          setMethodTitle(getIntl().common.selectService);
        }
        break;

      default:
        break;
    }
    setDiningMethod(type);
  };

  const handleAddShoppingCart = (favorite: IFavorite) => {
    dispatch({
      type: ActionType.ADD_TO_SHOPPING_CART,
      storeID: business.storeID,
      operate: 'ADD',
      favorite: favorite,
    });
  };

  const handleMinusShoppingCart = (favorite: IFavorite) => {
    dispatch({
      type: ActionType.ADD_TO_SHOPPING_CART,
      storeID: business.storeID,
      operate: 'MINUS',
      favorite: favorite,
    });
  };

  const handleCheckout = async () => {
    if (diningMethod !== undefined && shoppingCartData) {
      const order = getOrderByShoppingCart({
        type: diningMethod,
        shoppingCartData,
        table: tableDetails,
        deliveryAddress: addressList[0],
        pickUpOption,
      });
      
      const result = await overbooking(businessID, order);

      if (result) {
        dispatch({
          type: ActionType.CLEAR_SHOPPING_CART,
          storeID: business.storeID,
        });
        
        let domain = window.location.hostname;
        let search = window.location.search || '';
        let callBackUrl = '';

        if (domain === 'localhost') {
          domain = 'http://localhost:3106';
        };

        callBackUrl = `${domain}/${businessID}/menus/${search}`;
        
        setShowShoppingCartPopup(false);
        window.location.href = `${result.payment_link}?from=4&callback=${callBackUrl}`;
      }
    }
  };

  const getDisabled = useMemo(() => {
    let disabled = true;

    if (
      (diningMethod === IDiningMethods.DELIVERY && addressList.length > 0) ||
      (diningMethod === IDiningMethods.TAKEAWAY && pickUpOption) ||
      (diningMethod === IDiningMethods.DINE_IN && tableDetails)
    ) {
      disabled = false;
    }

    return disabled;
  }, [diningMethod, tableDetails, pickUpOption, addressList])

  return (
    <>
      <div className={`${prefix}`}>
        <Header logo={business.imgUrl || ''} />
        <div className={`${prefix}-content`}>
          <Business business={business} />
          <DiningMethodsPanel
            showOption={showOption}
            title={methodTitle}
            table={tableDetails}
            type={diningMethod}
            addressList={addressList}
            pickUpOption={pickUpOption}
            onClick={() => setShowPopup(true)}
          />
          <div className={`${prefix}-menus`}>
            <Tabs
              data={menusData}
              activeKes={activeKes}
              setActiveKes={setActiveKes}
            />
            <MenuList
              isFirst={isFirst}
              data={data}
              shoppingCartData={shoppingCartData}
              handleMinusShoppingCart={handleMinusShoppingCart}
              handleAddShoppingCart={handleAddShoppingCart}
            />
          </div>
          <BusinessInfoPanel business={business} />
          <ShoppingCartBar
            shoppingCartData={shoppingCartData}
            setShowShoppingCartPopup={setShowShoppingCartPopup}
          />
          <DiningMethods
            visible={showPopup}
            type={diningMethod}
            types={diningMethods}
            businessID={businessID}
            addressList={addressList}
            setType={changeType}
            setAddressList={setAddressList}
            handleChangeAddress={handleChangeAddress}
            onConfirm={handleConfirm}
            onClose={() => setShowPopup(false)}
          />
          <ShoppingCartPopup
            visible={showShoppingCartPopup}
            data={cartList}
            type={diningMethod}
            disabled={getDisabled}
            shoppingCartData={shoppingCartData}
            handleCheckout={handleCheckout}
            handleMinusShoppingCart={handleMinusShoppingCart}
            handleAddShoppingCart={handleAddShoppingCart}
            onClose={() => setShowShoppingCartPopup(false)}
          />
        </div>
      </div>
      <Toast
        show={showTips}
        icon={<SuccessIcon />}
        setShow={setShowTips}
        message={getIntl().common.seatedTips}
      />
    </>
  )
}

export default MenuListPage;