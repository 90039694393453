import { all } from 'redux-saga/effects';
import commonSaga from './commom-saga';
import cartSaga from './shopping-cart-saga';

const rootSaga = function* () {
  yield all([
    commonSaga(),
    cartSaga(),
  ]);
}

export default rootSaga;
