import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ChooseIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.25" stroke="#0094FF" strokeOpacity="0.2" strokeWidth="1.5" />
      </svg>
    </div>
  );
};

export default ChooseIcon;
