import { IBusiness } from "@/types/business-types";
import { prefix } from ".";
import { getIntl } from "@/App";

interface IBusinessInfoPanelProps {
  business: IBusiness;
}

const BusinessInfoPanel: React.FC<IBusinessInfoPanelProps> = (props) => {
  const { businessName, address, businessEmail, businessPhone, businessHighlight } = props.business;

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}-content`}>
        <div className={`${prefix}-content-title`}>{getIntl().common.about} {businessName}</div>
        <div className={`${prefix}-content-wrapper`}>
          <div className={`${prefix}-content-item`}>
            <div className="label">{getIntl().common.address}</div>
            <div>{address}</div>
          </div>
          <div className={`${prefix}-content-item`}>
            <div  className="label">{getIntl().common.email}</div>
            <div>{businessEmail || '--'}</div>
          </div>
          <div className={`${prefix}-content-item`}>
            <div className="label">{getIntl().common.phoneNumber}</div>
            <div>{businessPhone || '--'}</div>
          </div>
          <div className={`${prefix}-content-details`}>{businessHighlight || '--'}</div>
        </div>
      </div>
    </div>
  )
};

export default BusinessInfoPanel;