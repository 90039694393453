import { produce } from 'immer';
import { handleActions } from 'redux-actions';
import ActionType from '@/actions/action-type';
import { IBusiness } from '@/types/business-types';
import { ISetBusiness, ISetMenus } from '@/actions/common-action';
import { IMenuData } from '@/types/menu-types';

export interface ICommonState {
  business: IBusiness;
  menusData: IMenuData[]
}

const defaultBusiness: IBusiness = {
  id: '',
  storeID: '',
  address: '',
  businessEmail: '',
  businessName: '',
  businessPhone: '',
  businessHighlight: '',
  types: [],
}

const initState: ICommonState = {
  business: defaultBusiness,
  menusData: [],
};

const actions: any = {};

actions[ActionType.SET_BUSINESS] = (state: ICommonState, action: ISetBusiness) => produce(state, draft => {
  if (action.business) {
    draft.business = action.business;
  }
});

actions[ActionType.SET_MENUS] = (state: ICommonState, action: ISetMenus) => produce(state, draft => {
  if (action.menusData) {
    draft.menusData = action.menusData;
  }
});

export default handleActions(actions, initState);
