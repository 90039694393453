import { IFavorite } from "@/types/menu-types"
import { prefix } from ".";
import Config from "@/libs/config";
import { formatCurrency } from "@/utils";
import MinusIcon from "../svg/MinusIcon";
import AddIcon from "../svg/AddIcon";
import { IShoppingCartInfo } from "@/types/order-types";

interface IMenuItemProps {
  data: IFavorite;
  shoppingCartData: IShoppingCartInfo | undefined;
  className?: string;
  handleMinusShoppingCart: (favorite: IFavorite) => void;
  handleAddShoppingCart: (favorite: IFavorite) => void;
}

const MenuItem: React.FC<IMenuItemProps> = props => {
  const {
    data,
    shoppingCartData,
    className,
    handleMinusShoppingCart,
    handleAddShoppingCart,
  } = props;

  const { thumb_image_url, name, description, price } = data.listing;
  
  const { favoriteIdLineItemRef = {} } = shoppingCartData || {};

  const quantity = favoriteIdLineItemRef[data.id]?.quantity || 0;

  return (
    <div className={`${prefix}-item ${className}`}>
      <div className={`${prefix}-item-left`}>
        {thumb_image_url && <img className="img" src={`${thumb_image_url}`} alt="" />}
        {!thumb_image_url && <div className={`${prefix}-item-default`}><img className={`${prefix}-item-default-img`} src={`${Config.cdnUrl}/images/default-product.png`} alt="" /></div>}
        <div className={`${prefix}-item-left-details`}>
          <div>
            <div className={`${prefix}-item-left-details-title`}>{name}</div>
            <div className={`${prefix}-item-left-details-describe`}>{description}</div>
          </div>
          <div className={`${prefix}-item-left-details-price`}>{formatCurrency(Number(price))}</div>
        </div>
      </div>
      <div className={`${prefix}-item-right`}>
        {
          quantity > 0 &&
          <>
            <MinusIcon onClick={() => handleMinusShoppingCart(data)} />
            <div className={`${prefix}-item-right-quantity`}>{quantity}</div>
          </>
        }
        <AddIcon onClick={() => handleAddShoppingCart(data)} />
      </div>
    </div>
  )
}

export default MenuItem