import { IFavoriteSection } from "@/types/menu-types";
import { prefix } from ".";

interface ITabsItemsProps {
  data: IFavoriteSection[];
  activeKes: string[];
  setActiveKes: (value: string[]) => void;
}

const TabsItems: React.FC<ITabsItemsProps> = (props) => {
  const { data, activeKes, setActiveKes } = props;

  data.sort((a,b) => a.position - b.position);

  const key = activeKes[1];

  const handleItemClick = (record: IFavoriteSection) => {
    const newActiveKes = [...activeKes];
    newActiveKes[1] = `${record.id}`
    setActiveKes(newActiveKes)
  }

  return (
    <div className={`${prefix}-items`}>
      {
        data.map(item => (
          <div onClick={() => handleItemClick(item)} className={`${prefix}-items-item ${key === `${item.id}` ? 'active' : ''}`} key={item.id}>{item.name}</div>
        ))
      }
    </div>
  )
};

export default TabsItems;