import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ArrowDownIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66658 5.7143C2.66658 5.48573 2.74277 5.33334 2.89515 5.18096C3.19992 4.8762 3.65706 4.8762 3.96182 5.18096L7.99992 9.21906L12.038 5.18096C12.3428 4.8762 12.7999 4.8762 13.1047 5.18096C13.4094 5.48573 13.4094 5.94287 13.1047 6.24763L8.53325 10.8191C8.22849 11.1238 7.77135 11.1238 7.46658 10.8191L2.89515 6.24763C2.74277 6.09525 2.66658 5.94287 2.66658 5.7143Z" fill="#0094FF" />
      </svg>
    </div>
  );
};

export default ArrowDownIcon;
