import Action from "@/actions";
import ActionType from "@/actions/action-type";
import { IAddToShoppingCart } from "@/actions/shopping-cart-action";
import { IReducersState } from "@/reducers";
import { IShoppingCartState } from "@/reducers/shopping-cart-reducer";
import { minus, plus, times } from "@/utils";
import { getDefaultLineItem } from "@/utils/order-utils";
import { put, select, takeEvery } from "redux-saga/effects";


function* handleAddToShoppingCart(params: IAddToShoppingCart) {

  const { storeID, favorite, operate, callback } = params;
  const { id: favoriteId, listing } = favorite;
  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);

  const { shoppingCartData } = cartState;

  const newShoppingCartData = { ...shoppingCartData };

  if (newShoppingCartData[storeID]) {
    const { favoriteIdLineItemRef } = newShoppingCartData[storeID];
    const newlistingIdLineItemRef = { ...favoriteIdLineItemRef };
    const item = newlistingIdLineItemRef[favoriteId] || {};
    const { quantity = 0, uuid = '' } = item;
    const { price = 0 } = listing;

    if (!uuid) {
      newlistingIdLineItemRef[favoriteId] = getDefaultLineItem(listing);
    } else {
    
      if (operate === 'ADD') {
        const newQuantity = plus(quantity, 1);
        const newTotal = times(price, newQuantity, 2);
  
        newlistingIdLineItemRef[favoriteId] = {
          ...item,
          quantity: newQuantity,
          total: `${newTotal}`,
        }
      } else if (operate === 'MINUS') {
        const newQuantity = minus(quantity, 1);
        if (newQuantity === 0) {
          delete newlistingIdLineItemRef[favoriteId];
        } else {
          const newQuantity = minus(quantity, 1);
          const newTotal = times(price, newQuantity, 2)
          newlistingIdLineItemRef[favoriteId] = {
            ...item,
            quantity: newQuantity,
            total: `${newTotal}`,
          }
        }
      };

    }

    newShoppingCartData[storeID] = { favoriteIdLineItemRef: newlistingIdLineItemRef };
  } else {
    if (operate === 'ADD') {
      newShoppingCartData[storeID] = {
        favoriteIdLineItemRef: {
          [favoriteId]: getDefaultLineItem(listing),
        }
      }
    }
  };

  yield put<Action>({
    type: ActionType.SET_SHOPPING_CART_DATA,
    data: newShoppingCartData,
  });
  
  if (callback) {
    callback(newShoppingCartData[storeID]);
  }

};

function* shoppingCartSaga() {
  yield takeEvery<string, any>(ActionType.ADD_TO_SHOPPING_CART, handleAddToShoppingCart);
};

export default shoppingCartSaga;

