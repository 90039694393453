import { IFavorite } from "@/types/menu-types";
import { prefix } from ".";
import MenuItem from "./MenuItem";
import { IShoppingCartInfo } from "@/types/order-types";

interface IMenuListProps {
  data: IFavorite[];
  isFirst: boolean;
  shoppingCartData: IShoppingCartInfo | undefined;
  handleAddShoppingCart: (favorite: IFavorite) => void;
  handleMinusShoppingCart: (favorite: IFavorite) => void;
  className?: string;
}

const MenuList: React.FC<IMenuListProps> = props => {
  const {
    data,
    isFirst,
    className = '',
    shoppingCartData,
    handleAddShoppingCart,
    handleMinusShoppingCart,
  } = props

  return (
    <div className={`${prefix} ${className} menus ${isFirst ? 'isFirst' : ''}`}>
      {
        data.map(item => (
          <MenuItem
            key={item.id}
            data={item}
            shoppingCartData={shoppingCartData}
            handleMinusShoppingCart={handleMinusShoppingCart}
            handleAddShoppingCart={handleAddShoppingCart}
          />
        ))
      }
    </div>
  )
}

export default MenuList;