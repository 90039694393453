const common = {
  "confirm": "Confirm",
  "save": "Save",
  "headerTitle": "Powered by Wonder",
  "checkout": "Checkout",
  "addAddress": "Add New Address",
  "address": "Address",
  "email": "Email",
  "about": "About",
  "noAddressTips": "No saved address",
  "receiverName": "Receiver Name",
  "receiverNamePlaceholder": "Enter Receiver Name",
  "receiverNameRequired": "Receiver Name is Required",
  "phoneNumber": "Phone Number",
  "phoneNumberPlaceholder": "Enter Phone Number",
  "phoneNumberRequired": "Phone Number is Required",
  "addressLabel": "Address (Complex, Street, District)",
  "addressLabelPlaceholder": "Enter Address",
  "street": "Building/Block/Floor/Room",
  "addressRequired": "Address is Required",
  "area": "Area",
  "deliveryNote": "Delivery Note",
  "deliveryNotePlaceholder": "Enter Any delivery note",
  "search": "Search",
  "takeawayTitle": "Edit Pick-Up Info",
  "deliveryTitle": "Change Address",
  "dineInTitle": "Edit Dine-In info",
  "selectService": "Select preferred service",
  "noDeliveryTips": "No Delivery Address Yet",
  "addNew": "Add New",
  "edit": "Edit",
  "dineIn": "Dine In",
  "takeaway": "Takeaway",
  "delivery": "Delivery",
  "addNewAddress": "Add New Address",
  "pickUpTime": "Edit Pick-Up Time",
  "immediately": "Immediately",
  "scheduleForLater": "Schedule for later",
  "chooseTime": "Choose time",
  "scanTips": "Scan To Order QR code",
  "cart": "Cart",
  "confirmAndCheckout": "Confirm and Checkout",
  "items": "items",
  "subtotal": "Subtotal",
  "deliveryFee": "Delivery fee",
  "total": "Total",
  "invalidOrTips": "Invalid QR code.\nPlease scan again with another order QR code.",
  "seatedTips": "Table Seated Please Enjoy Your Meal!",
};

export default common;