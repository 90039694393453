import { IDeliveryAddress, IDiningMethods, IPickUpOption } from "@/types/common-types";
import { prefix } from ".";
import AddressIcon from "../svg/AddressIcon";
import PickUpIcon from "../svg/PickUpIcon";
import DineInIcon from "../svg/DineInIcon";
import { getIntl } from "@/App";
import { ITable } from "@/types/table-types";

interface IDiningMethodsPanelProps {
  title: string;
  showOption: boolean;
  type: IDiningMethods | undefined;
  table: ITable | undefined;
  addressList: IDeliveryAddress[];
  pickUpOption: IPickUpOption | undefined;
  onClick: () => void;
};

const DiningMethodsPanel: React.FC<IDiningMethodsPanelProps> = (props) => {
  const {
    type,
    title,
    table,
    showOption,
    addressList,
    pickUpOption,
    onClick,
  } = props;

  const handleClick = () => {
    onClick()
  };

  const addressRecord = addressList[0];

  return (
    <div className={`${prefix}`}>
      {type === IDiningMethods.DELIVERY && <AddressIcon className={`${prefix}-icon`} />}
      {type === IDiningMethods.TAKEAWAY && <PickUpIcon className={`${prefix}-icon`} />}
      {type === IDiningMethods.DINE_IN && <DineInIcon className={`${prefix}-icon`} />}
      {!showOption && <div className={`${prefix}-title`}>{title}</div>}
      {
        showOption && type === IDiningMethods.DELIVERY && addressRecord &&
        <div className={`${prefix}-content`}>
          <div className={`${prefix}-content-title`}>{addressRecord.address}</div>
          <div className={`${prefix}-content-street`}>{addressRecord.street}</div>
          <div>{addressRecord.name} - {addressRecord.phone}</div>
        </div>
      }
      {
        showOption && type === IDiningMethods.TAKEAWAY && pickUpOption &&
        <div className={`${prefix}-content`}>
          <div className={`${prefix}-content-title`}>Pick-up: {pickUpOption.delivery_date}</div>
          <div className={`${prefix}-content-details`}>{pickUpOption.name} - {pickUpOption.phone}</div>
        </div>
      }
      {
        showOption && type === IDiningMethods.DINE_IN && table &&
        <div className={`${prefix}-content`}>
          <div className={`${prefix}-content-title`}>Table: {table.name}</div>
          {/* <div className={`${prefix}-content-title`}>{table.updated_at}</div> */}
        </div>
      }
      <div onClick={handleClick} className={`${prefix}-btn`}>
        {type === IDiningMethods.DELIVERY && addressList.length < 1 ? getIntl().common.addNew : getIntl().common.edit}
      </div>
    </div>
  )
};

export default DiningMethodsPanel;