import React from 'react';
import type { IIconProps } from '@/types/common-types';

const ArrowClickIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.71454 13.3334C5.48597 13.3334 5.33359 13.2572 5.18121 13.1048C4.87645 12.8001 4.87645 12.3429 5.18121 12.0382L9.2193 8.00008L5.18121 3.96199C4.87645 3.65722 4.87645 3.20008 5.18121 2.89532C5.48597 2.59056 5.94311 2.59056 6.24788 2.89532L10.8193 7.46675C11.1241 7.77151 11.1241 8.22865 10.8193 8.53342L6.24788 13.1048C6.09549 13.2572 5.94311 13.3334 5.71454 13.3334Z" fill="#0094FF" />
      </svg>
    </div>
  );
};

export default ArrowClickIcon;
