import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DineInIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4824 16.8519H3.51273C2.84648 16.8519 2.30469 16.3101 2.30469 15.6438V8.09229C2.30469 7.75873 2.57513 7.48828 2.9087 7.48828C3.24227 7.48828 3.51271 7.75873 3.51271 8.09229V15.6438H14.4824V8.09229C14.4824 7.75873 14.7528 7.48828 15.0864 7.48828C15.42 7.48828 15.6904 7.75873 15.6904 8.09229V15.6438C15.6904 16.3101 15.1486 16.8519 14.4824 16.8519Z" fill="#0094FF" />
        <path d="M4.63294 9.25098C4.02007 9.25098 3.46943 9.1227 2.99459 8.8695C2.98595 8.86485 2.9774 8.86003 2.96894 8.85506C1.8479 8.24588 1.15187 7.07781 1.15039 5.80001C1.15039 5.55773 1.24832 5.28712 2.21392 3.17792C2.44368 2.67565 2.66162 2.19993 2.80113 1.87595C2.99137 1.4337 3.42668 1.14746 3.90977 1.14746H14.1285C14.6146 1.14746 15.0511 1.4362 15.2404 1.88316C15.3776 2.20728 15.5905 2.68096 15.8153 3.18071C16.7593 5.28077 16.8549 5.55034 16.8537 5.79099C16.854 7.0623 16.16 8.23347 15.0422 8.84412C14.5636 9.10645 14.0044 9.24124 13.3812 9.24419C12.5672 9.24419 11.7936 8.96577 11.1795 8.46483C10.5732 8.96046 9.81107 9.23947 9.01239 9.2458C8.20251 9.24079 7.4351 8.96251 6.82547 8.4669C6.21259 8.96845 5.44135 9.24863 4.63294 9.25098ZM3.57797 7.81113C3.8729 7.96495 4.22711 8.04294 4.63117 8.04294C5.30066 8.04102 5.93211 7.74623 6.36212 7.23425C6.41872 7.16679 6.48939 7.11254 6.56917 7.0753C6.64896 7.03805 6.73593 7.01872 6.82398 7.01864H6.82457C6.91256 7.01863 6.99949 7.03786 7.07927 7.07498C7.15905 7.11209 7.22975 7.1662 7.28643 7.23351C7.71349 7.74049 8.34227 8.03366 9.01147 8.03778C9.66681 8.03247 10.2912 7.73871 10.7165 7.23189C10.9456 6.95835 11.4116 6.95804 11.642 7.2316C12.0711 7.74301 12.704 8.03618 13.3782 8.03618C13.7955 8.03411 14.1613 7.94948 14.4624 7.78446C14.8126 7.5932 15.1063 7.31309 15.3139 6.97229C15.5215 6.63149 15.6357 6.24202 15.6451 5.84307C15.5557 5.55005 15.0517 4.42844 14.7134 3.67606C14.4848 3.1676 14.2683 2.68554 14.1285 2.3555H3.90976C3.76819 2.68436 3.5464 3.16907 3.31222 3.68077C2.96685 4.43551 2.4522 5.55992 2.3593 5.85647C2.38054 6.67373 2.83738 7.41576 3.56173 7.80212C3.56705 7.80509 3.57266 7.80804 3.57797 7.81113ZM14.1285 2.3555H14.1315H14.1285Z" fill="#0094FF" />
        <path d="M9.75614 14.7383H8.24609V11.7183C8.24609 11.3847 8.51652 11.1143 8.85011 11.1143H9.15212C9.48571 11.1143 9.75614 11.3847 9.75614 11.7183V14.7383Z" fill="#4C95FF" />
      </svg>
    </div>
  );
};

export default DineInIcon;
