import { IDeliveryAddress } from "@/types/common-types";
import AddAddressIcon from "../svg/AddAddressIcon";
import { prefix } from ".";
import { getIntl } from "@/App";
import NoAddressIcon from "../svg/NoAddressIcon";
import { Button, Input, Form } from 'react-vant'
import ArrowDownIcon from "../svg/ArrowDownIcon";
import AddressSearch from "./AddressSearch";
import ChooseIcon from "../svg/ChooseIcon";
import ChoosedIcon from "../svg/ChoosedIcon";
import EditAddressIcon from "../svg/EditAddressIcon";
import { useState } from "react";
import { Constants } from "@/constants";
import { setLocalStorageJson } from "@/utils";


interface ICustomizedProps {
  value?: string;
  onClick?: () => void;
  onChange?: (value: string) => void;
};

const AddressInput: React.FC<ICustomizedProps> = (props) => {
  const { value, onClick } = props;

  return (
    <div onClick={onClick} className={`${prefix}-input ${value ? '' : 'placeholder'}`}>{value || getIntl().common.addressLabelPlaceholder}</div>
  )
};

const PhoneInput: React.FC<ICustomizedProps> = (props) => {
  const { value, onChange } = props;

  let showValue = value;
  if (value && value.indexOf('+') > -1) {
    showValue = value.split(' ')[1];
  }
  
  return (
    <div className={`${prefix}-delivery-phone`}>
      <img
        width="20px"
        height="20px"
        src={`https://mb5-stg-cdn.bindo.co/images/country-flag/hk.svg`}
        alt=""
      />
      <div className={`${prefix}-delivery-phone-number`}>+852</div>
      <ArrowDownIcon className={`${prefix}-delivery-phone-icon`} />
      <Input value={showValue} onChange={onChange} placeholder={getIntl().common.phoneNumberPlaceholder} />
    </div>
  )
}

interface IDeliveryProcessProps {
  addressList: IDeliveryAddress[];
  showForm: boolean;
  showSearch: boolean;
  isUpdate: boolean;
  setTitle: (value: string) => void;
  setShowTabs: (value: boolean) => void;
  setShowForm: (value: boolean) => void;
  setShowSearch: (value: boolean) => void;
  setIsUpdate: (value: boolean) => void;
  setAddressList: (record: IDeliveryAddress[]) => void;
  onConfirm: (option?: any) => void;
  handleChangeAddress: (index: number) => void;
};

const DeliveryProcess: React.FC<IDeliveryProcessProps> = (props) => {
  const {
    addressList,
    showSearch,
    showForm,
    isUpdate,
    setTitle,
    setShowTabs,
    setShowForm,
    setShowSearch,
    onConfirm,
    setIsUpdate,
    setAddressList,
    handleChangeAddress
  } = props;

  const [form] = Form.useForm();
  const phonePrefix = '+852';
  
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [latValue, setLatValue] = useState<string>('');
  const [lngValue, setLngValue] = useState<string>('');

  const onFinish = (values: any) => {
    const newValues = {
      ...values,
      phone: `${phonePrefix} ${values.phone}`,
      lat: latValue,
      lng: lngValue,
    };

    if (isUpdate) {
      setShowForm(false);
      if (addressList[editIndex]) {
        const newList = [...addressList];
        newList[editIndex] = newValues;
        setAddressList(newList);
        setLocalStorageJson(Constants.DELIVERY_ADDRESS, newList);
        setIsUpdate(false);
      }
    } else {
      if (!showForm) {
        onConfirm()
      } else {
        onConfirm(newValues);
      }
    }
  };

  const handleAddressChange = (value: string, lat: string, lng: string) => {
    setShowSearch(false);
    setLatValue(lat);
    setLngValue(lng);
    form.setFieldValue('address', value);
  };

  const handleEdit = (record: IDeliveryAddress, index: number) => {
    form.setFieldsValue({
      ...record,
      phone: record.phone.split(' ')[1],
    });
    setIsUpdate(true);
    setShowForm(true);
    setShowTabs(false)
    setEditIndex(index);
  };

  const handleNew = () => {
    form.setFieldsValue({
      name: '',
      phone: '',
      address: '',
      street: '',
      node: '',
    });
    setTitle(getIntl().common.addNewAddress);
    setShowForm(true);
    setShowTabs(false);
  };
  
  return (
    <div className={`${prefix}-delivery`}>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
      >
        {
          !showForm &&
          <>
            {
              addressList.length > 0 &&
              <div className={`${prefix}-delivery-list`}>
                {
                  addressList.map((item, index) => (
                    <div key={`${item.name}-${index}`} className={`${prefix}-delivery-list-item`}>
                      <div className={`${prefix}-delivery-list-item-details`}>
                        <div>{item.name}</div>
                        <div>{item.phone}</div>
                        <div>{item.address}</div>
                        <div>{item.street}</div>
                      </div>
                      <div className={`${prefix}-delivery-list-item-actions`}>
                        <div onClick={() => handleChangeAddress(index)}>{index === 0 ? <ChoosedIcon /> :  <ChooseIcon />}</div>
                        <EditAddressIcon onClick={() => handleEdit(item, index)} />
                      </div>
                    </div>
                  ))
                }
              </div>
            }
            <div className={`${prefix}-delivery-add`} onClick={handleNew}>
              <AddAddressIcon className={`${prefix}-delivery-add-icon`} />
              <div>{getIntl().common.addAddress}</div>
            </div>
            {
              addressList.length < 1 &&
              <div className={`${prefix}-delivery-noData`}>
                <NoAddressIcon />
                <div className="tips">{getIntl().common.noAddressTips}</div>
              </div>
            }
          </>
        }
        {
          showForm &&
          <>
            {
              !showSearch &&
              <div className="rv-form-content">
                <Form.Item
                  rules={[{ required: true, message: getIntl().common.receiverNameRequired }]}
                  name='name'
                  label={getIntl().common.receiverName}
                >
                  <Input placeholder={getIntl().common.receiverNamePlaceholder} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: getIntl().common.phoneNumberRequired }]}
                  name='phone'
                  label={getIntl().common.phoneNumber}
                >
                  <PhoneInput />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: getIntl().common.addressRequired }]}
                  name='address'
                  label={getIntl().common.addressLabel}
                >
                  <AddressInput onClick={() => setShowSearch(true)} />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: getIntl().common.addressRequired }]}
                  name='street'
                  label={getIntl().common.street}
                >
                  <Input placeholder={getIntl().common.addressLabelPlaceholder} />
                </Form.Item>
                <Form.Item
                  initialValue={'Hong Kong'}
                  name='province'
                  label={getIntl().common.street}
                >
                  <div className={`${prefix}-input`}>Hong Kong Island</div>
                </Form.Item>
                <Form.Item
                  name='node'
                  label={getIntl().common.deliveryNote}
                >
                  <Input placeholder={getIntl().common.deliveryNotePlaceholder} />
                </Form.Item>
              </div>
            }
            {
              showSearch &&
              <AddressSearch
                onChange={handleAddressChange}
              />
            }
          </>
        }
        <Button nativeType='submit' type='primary'>{getIntl().common.confirm}</Button>
      </Form>
    </div>
  )
};

export default DeliveryProcess;