import React from 'react';
import type { IIconProps } from '@/types/common-types';

const DeliveryIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4783 5.64256H10.4799V13.4145H20.8783V12.5003V4.72878H10.4783V5.64256ZM8.87988 13.4145V7.30021H5.96924L5.35644 9.13862C5.26 9.42795 5.06987 9.67687 4.81612 9.84604L2.76406 11.2141V12.5003V13.4145H8.87988ZM1.16406 11.0917V12.5003V13.6431V17.4146C1.16406 17.9826 1.62457 18.4431 2.19263 18.4431H4.54902C4.08407 18.8902 3.79473 19.5186 3.79473 20.2145C3.79473 21.5716 4.89483 22.6717 6.25187 22.6717C7.60891 22.6717 8.70901 21.5716 8.70901 20.2145C8.70901 19.5186 8.41967 18.8902 7.95472 18.4431H16.549C16.0841 18.8902 15.7947 19.5186 15.7947 20.2145C15.7947 21.5716 16.8948 22.6717 18.2519 22.6717C19.6089 22.6717 20.709 21.5716 20.709 20.2145C20.709 19.5186 20.4197 18.8902 19.9547 18.4431H21.4498C22.0178 18.4431 22.4783 17.9826 22.4783 17.4146V13.6431V12.5003V4.50021C22.4783 3.74279 21.8643 3.12878 21.1069 3.12878H10.2498C9.49236 3.12878 8.87835 3.7428 8.87835 4.50021V5.70021H5.8045C5.21419 5.70021 4.69012 6.07794 4.50345 6.63796L3.86334 8.55826L1.77476 9.95065C1.39323 10.205 1.16406 10.6332 1.16406 11.0917ZM2.76406 16.8431V15.0145H20.8783V16.8431H2.76406ZM6.25187 21.0717C6.72526 21.0717 7.10901 20.6879 7.10901 20.2145C7.10901 19.7412 6.72526 19.3574 6.25187 19.3574C5.77848 19.3574 5.39473 19.7412 5.39473 20.2145C5.39473 20.6879 5.77848 21.0717 6.25187 21.0717ZM19.109 20.2145C19.109 20.6879 18.7253 21.0717 18.2519 21.0717C17.7785 21.0717 17.3947 20.6879 17.3947 20.2145C17.3947 19.7412 17.7785 19.3574 18.2519 19.3574C18.7253 19.3574 19.109 19.7412 19.109 20.2145Z" fill="currentColor" />
      </svg>
    </div>
  );
};

export default DeliveryIcon;
