import React from 'react';
import type { IIconProps } from '@/types/common-types';

const MinusIcon: React.FC<IIconProps> = (props) => {
  const { className = '', onClick } = props;
  return (
    <div className={`${className}`} onClick={onClick}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
          <rect x="0.138672" width="20" height="20" rx="10" fill="white" />
          <rect x="0.638672" y="0.5" width="19" height="19" rx="9.5" stroke="#333333" strokeOpacity="0.2" />
        </g>
        <path d="M5.63867 10.3999C5.63867 10.0686 5.9073 9.79993 6.23867 9.79993H14.0387C14.37 9.79993 14.6387 10.0686 14.6387 10.3999C14.6387 10.7313 14.37 10.9999 14.0387 10.9999H6.23867C5.9073 10.9999 5.63867 10.7313 5.63867 10.3999Z" fill="#8E8E93" />
      </svg>
    </div>
  );
};

export default MinusIcon;
