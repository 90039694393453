import ActionType from "@/actions/action-type";
import { ILoadingMainData } from "@/actions/common-action";
import { IReducersState } from "@/reducers";
import Action from '@/actions';
import { put, select, takeEvery } from "redux-saga/effects";
import { IMenuData } from "@/types/menu-types";
import { queryBusiness } from "@/interface/business";
import { IObject } from "@/types/common-types";
import { analysisBusiness } from "@/utils/business-utils";
import { queryMenus } from "@/interface/menu";
import { getLocalStorageJson } from "@/utils";
import { Constants } from "@/constants";

function* handleLoadingData(params: ILoadingMainData) {
  const { businessId } = params;
  const reducersState: IReducersState = yield select((state: IReducersState) => state);
  const { business, menusData } = reducersState.common;
  if (!business.id && businessId) {
    const result: IObject = yield queryBusiness(businessId);
    const business = analysisBusiness(result);
    yield put<Action>({
      type: ActionType.SET_BUSINESS,
      business,
    });
  };

  if (menusData.length < 1) {
    const menus: IMenuData[] =  yield queryMenus(businessId)
    yield put<Action>({
      type: ActionType.SET_MENUS,
      menusData: menus,
    });
  };

  const shoppingCartData = getLocalStorageJson(Constants.SHOPPING_CART_DATA);

  if (shoppingCartData) {
    yield put<Action>({
      type: ActionType.SET_SHOPPING_CART_DATA,
      data: shoppingCartData,
    });
  }

}

function* commonSaga() {
  yield takeEvery<string, any>(ActionType.LOADING_MAIN_DATA, handleLoadingData);
}

export default commonSaga;