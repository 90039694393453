import Config from "@/libs/config";
import { IBusiness } from "@/types/business-types";
import { IDiningMethods, IObject } from "@/types/common-types";

export const analysisBusiness = (data: IObject) => {
  let result: IBusiness;
  const { business = {}, store = {} } = data;
  const {
    id ='',
    business_name = '',
    business_email = '',
    business_phone = '',
    address_1 = '',
    address_2 = '',
    business_highlight = '',
    business_pictures = [],
  } = business;

  let imgUrl = getStorePicture(business_pictures);

  result = {
    id,
    storeID: store.id || '',
    imgUrl,
    address: `${address_1} ${address_2}`,
    businessName: business_name,
    businessEmail: business_email,
    businessPhone: business_phone,
    businessHighlight: business_highlight,
    types: [IDiningMethods.DELIVERY, IDiningMethods.TAKEAWAY],
  };

  return result;
};

export const getStorePicture = (pictures: any) => {
  let imgUrl = '';
  if (Array.isArray(pictures) && pictures.length > 0) {
    const firstPicture = pictures[0];
    if (
      firstPicture &&
      firstPicture.directus_files_id &&
      firstPicture.directus_files_id.id
    ) {
      imgUrl = `${Config.urls.gatewayUrl}/api/wonder-registry/files/${firstPicture.directus_files_id.id}`;
    }
  }

  return imgUrl;
};