import { IDeliveryAddress } from "./common-types";


export enum PurchasableType {
  LISTING = 'Listing',
  DISCOUNT = 'Discount',
  VOUCHER_DISCOUNT =  'VoucherDiscount',
  TAX =  'Tax',
  DELIVERY = 'Delivery',
  CHARGE = 'Charge',
  STORE_CREDIT = 'StoreCredit',
  LOYALTY_REDEEM = 'LoyaltyRedeem',
  GIFT_CARD = 'GiftCard',
  OCTOPUS = 'OctopusValue',
  SERVICE_FEE = 'ServiceFee',
  TIPS = 'Tips',
  ROUNDING = 'Rounding',
  DEPOSIT = 'Deposit',
  DEPOSIT_REDEEM = 'DepositRedeem',
  DEPOSIT_FORFEIT = 'DepositForfeit',
};

export interface ListingCountRef {
  [listingID: string]: ILineItem;
};

export interface ILineItem {
  uuid: string;
  purchasable_type: PurchasableType;
  purchasable_id: string;
  price: string;
  quantity: number;
  total: string;
  label: string;
}

export interface IShoppingCartInfo {
  favoriteIdLineItemRef: ListingCountRef;
}

export interface IParty {
  id: string;
  table_id: string;
  table_name: string;
  seated_at: string;
  number_of_people: number;
  status?: string;
}

export interface IOrderParams {
  order: IOrderInfo;
  party?: IParty;
  delivery_note?: IDeliveryAddress;
}

export interface IOrderInfo {
  currency: string;
  reference_number: string;
  shipping_method: number;
  line_items: ILineItem[];
  initial_delivery?: number;
  due_date?: string;
  tips?: string;
  note?: string;
  callback_url?: string;
  redirect_url?: string;
  is_only_pre_auth?: boolean;
}