import CurrencyKV from '@/constants/currencies';
import BigNumber from 'bignumber.js';
import { trim } from 'lodash';
import { v4 as uuidV1 } from 'uuid';

export const createUuid = (): string => uuidV1();

export const formatCurrency = (amount: number, currencyCode = 'HKD') => {
  if (CurrencyKV[currencyCode]) {
    currencyCode = CurrencyKV[currencyCode];
  }
  if (amount < 0) {
    return `-${currencyCode}${new BigNumber(Math.abs(amount)).toFormat(2)}`;
  }
  return `${currencyCode}${new BigNumber(amount).toFormat(2)}`;
};

export const handleNameDisplay = (text: string) => {
  if (text) {
    const value = trim(text.replace(/[^\u4e00-\u9fa50-9A-Za-z ]/g, ''));
    if (value) {
      const tArray = value.split(/\s+/);
      const first = tArray[0].toLocaleUpperCase();
      const end = tArray[tArray.length - 1].toLocaleUpperCase();

      if (tArray.length === 1) {
        if (first.charCodeAt(0) > 255) {
          return first.substring(first.length > 1 ? first.length - 2 : 0);
        }
        return first.charAt(0);
      }

      return `${first.charAt(0)}${end.charAt(0)}`;
    }
  }
};

/**
 * 在本地保存json对象
 * @param key 储存标识
 * @param json
 */
export const setLocalStorageJson = (key: string, json: any): void => {
  localStorage.setItem(key, JSON.stringify(json));
};

/**
 * 从本地获取json对象
 * @param key 储存标识
 */
export const getLocalStorageJson = (key: string): any => {
  let json: any = localStorage.getItem(key);

  if (json) {
    json = JSON.parse(json);
  }

  return json || undefined;
};

/**
 * 在本地保存json对象
 * @param key 储存标识
 * @param json
 */
export const setSessionStorageJson = (key: string, json: any): void => {
  sessionStorage.setItem(key, JSON.stringify(json));
};

/**
 * 从本地获取json对象
 * @param key 储存标识
 */
export const getSessionStorageJson = (key: string): any => {
  let json: any = sessionStorage.getItem(key);

  if (json) {
    json = JSON.parse(json);
  }

  return json || undefined;
};

export const plus = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).plus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const minus = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).minus(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const times = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).times(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

export const dividedBy = (
  num1: number,
  num2: number,
  decimal?: number | undefined
) => {
  let num: any = new BigNumber(num1).dividedBy(num2);

  if (decimal !== undefined && decimal !== null) {
    num = num.toFixed(decimal);
  }

  return Number(num);
};

