import common, { ICommonState } from "./common-reducer";
import cart, { IShoppingCartState } from "./shopping-cart-reducer";

export interface IReducersState {
  common: ICommonState;
  cart: IShoppingCartState;
}

const reducers = {
  common,
  cart,
};

export default reducers;
